import { createGlobalStyle } from 'styled-components';
import * as defaultTheme from '../variables';
import * as fonts from '../../fonts/fonts';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    color: ${defaultTheme.textColor};
    background-color: ${defaultTheme.black};
    font-family: ${defaultTheme.fontFamily};
    font-size: 14px;
    line-height: ${defaultTheme.lineHeight};
  }

  *,
  *:focus {
    outline: none;
  }

  *::-moz-focus-inner {
    border: 0 none;
  }

  a {
    display: inline-block;
    max-width: 100%;
    color: inherit;
    text-decoration: none;
  }

  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }

  p {
    margin: 16px 0;
  }

  b,
  strong {
    font-weight: 600;
  }

  dl,
  ol,
  ul {
    margin-top: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    line-height: inherit;
  }

  hr {
    height: 0;
    margin: 20px 0;
    padding: 0;
    border: 0;
    border-top: 1px solid ${defaultTheme.borderColor};
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  textarea {
    resize: vertical;
  }

  small {
    font-size: 88%;
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'), url(${
      fonts.Roboto400Woff2
    }) format('woff2'), url(${fonts.Roboto400Woff}) format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'), url(${
      fonts.Roboto700Woff2
    }) format('woff2'), url(${fonts.Roboto700Woff}) format('woff');
  }

  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: local('Roboto Black'), local('Roboto-Black'), url(${
      fonts.Roboto900Woff2
    }) format('woff2'), url(${fonts.Roboto900Woff}) format('woff');
  }
`;

export default GlobalStyle;

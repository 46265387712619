import React, { Component } from 'react';
import cx from 'classnames';

import CookieDisclaimerStyled from './CookieDisclaimer.style';

import { setCookie, getCookie } from '../../helpers/cookies';

import Button from '../Button/Button';

class CookieDisclaimer extends Component {
  constructor() {
    super();
    this.state = {
      showDisclaimer: false,
      runAnimation: false,
    };
  }

  componentDidMount() {
    const cookieDisclaimer = getCookie('cookieDisclaimer');
    if (cookieDisclaimer !== 'dismissed') {
      this.setState({ showDisclaimer: true });
    }
  }

  onHideDisclaimer = () => {
    setCookie('cookieDisclaimer', 'dismissed', 365);

    this.setState(prevState => ({
      runAnimation: !!prevState,
    }));

    setTimeout(() => {
      this.setState({ showDisclaimer: false });
    }, 2000);
  };

  render() {
    const { showDisclaimer, runAnimation } = this.state;

    return (
      <CookieDisclaimerStyled>
        {showDisclaimer && (
          <div
            className={cx('CookieDisclaimer__inner', {
              isShow: showDisclaimer,
              isHide: runAnimation,
            })}
          >
            <h5 className="CookieDisclaimer__title">ABOUT COOKIES</h5>
            <p className="CookieDisclaimer__desc">
              <span>
                We use cookies to help make this website better, to improve our
                services and for advertising purposes. You can learn more about
                our use of cookies in our privacy policy by clicking
              </span>{' '}
              <a target="_blank" href="/">
                <strong>here</strong>
              </a>
              . Otherwise, we’ll assume you are OK to continue.
            </p>
            <Button
              className="CookieDisclaimer__btn"
              onClick={this.onHideDisclaimer}
            >
              CONTINUE
            </Button>
          </div>
        )}
      </CookieDisclaimerStyled>
    );
  }
}

export default CookieDisclaimer;

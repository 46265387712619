import styled from 'styled-components';
import * as defaultTheme from '../variables';

import logoRsStar from '../../images/logo_rs_star.svg';
import logoRsText from '../../images/logo_rs_text.svg';
import logoD2Min from '../../images/logo_d2_min.svg';
import logoDestiny from '../../images/logo_destiny.svg';

const HeaderStyled = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  z-index: 100;

  .Header__inner {
    width: 100%;
    max-width: 1270px;
    height: 60px;
    margin: 0 auto;
    padding: 0 10px;

    @media (${p => p.theme.smMin}) {
      padding: 0 20px;
    }

    @media (${p => p.theme.lgMin}) {
      padding: 0;
    }
  }

  .Header__inner,
  .Header__logos,
  .Header__nav,
  .Header__links {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Header__logo {
    background-repeat: no-repeat;
    background-size: 100%;

    @media (${p => p.theme.mdMax}) {
      margin-right: 18px;
    }

    @media (${p => p.theme.lgMin}) {
      margin-right: 53px;
    }

    &.rs {
      @media (${p => p.theme.mdMax}) {
        width: 48px;
        height: 46px;
        background-image: url(${logoRsStar});
      }

      @media (${p => p.theme.lgMin}) {
        width: 133px;
        height: 47px;
        background-image: url(${logoRsText});
      }
    }

    &.d2 {
      @media (${p => p.theme.mdMax}) {
        width: 75px;
        height: 42px;
        background-image: url(${logoD2Min});
      }

      @media (${p => p.theme.lgMin}) {
        width: 175px;
        height: 46px;
        margin-top: 5px;
        background-image: url(${logoDestiny});
      }
    }
  }

  .Header__links {
    @media (${p => p.theme.xsMax}) {
      display: none;
    }
  }

  .Header__dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 230px;

    &::before {
      content: '';
      position: absolute;
      top: -60px;
      right: -13px;
      width: 60px;
      height: 60px;
    }
  }

  .Header__link,
  .Header__dropdownLink {
    position: relative;
    display: block;
    padding: 4px 0;
    color: #fff;
    background: none;
    font-size: 12px;
    letter-spacing: 4px;
    text-align: left;
    text-transform: uppercase;
    border: none;
    cursor: pointer;

    &::after {
      position: absolute;
      display: block;
      bottom: 4px;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #fff;
      opacity: 0;
      transition: 0.15s ease-in-out;
    }

    &.active::after {
      opacity: 1;
    }

    &.xsHidden {
      @media (${p => p.theme.xsMax}) {
        display: none !important;
      }
    }

    &.smHidden {
      @media (${p => p.theme.smMin}) and (${p => p.theme.smMax}) {
        display: none !important;
      }
    }

    &.lgHidden {
      @media (${p => p.theme.lgMin}) {
        display: none !important;
      }
    }

    &.mdHidden {
      @media (${p => p.theme.mdMin}) {
        display: none !important;
      }
    }
  }

  .Header__link {
    margin-right: 30px;
  }

  .Header__dropdownLink {
    width: 100%;
  }

  .Header__lang {
    margin-left: 30px;

    @media (${p => p.theme.smMax}) {
      display: none;
    }
  }

  .Header__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 60px;
    margin-left: 18px;
  }

  .Header__menuBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    color: #fff;
    background-color: transparent;
    font-size: 20px;
    border: none;
    cursor: pointer;
  }
`;

HeaderStyled.defaultProps = {
  theme: defaultTheme,
};

export default HeaderStyled;

import React from 'react';
import PropTypes from 'prop-types';

import ModalProgramOverStyled from './ModalProgramOver.style';
import bgHeroCsDesktop from '../../images/bg_HeroCs_dsktp.jpg';

import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import ModalClose from '../ModalClose/ModalClose';

const ModalProgramOver = ({ onClose }) => {
  return (
    <Modal>
      <ModalClose onClick={onClose} />
      <ModalProgramOverStyled>
        <Heading className="ModalProgramOver__title" size="xs" full>
          PROGRAM HAS ENDED
        </Heading>
        <div className="ModalProgramOver__desc">
          <img src={bgHeroCsDesktop} alt="" />
          <p>
            THANKS TO ALL THE FANS THAT PARTICIPATED. STAY TUNED FOR THE NEXT
            GAMING EXPERIENCE FROM ROCKSTAR ENERGY DRINK.
          </p>
        </div>
        <Button
          className="ModalProgramOver__back"
          variant="secondary"
          onClick={onClose}
        >
          CLOSE
        </Button>
      </ModalProgramOverStyled>
    </Modal>
  );
};

ModalProgramOver.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalProgramOver;

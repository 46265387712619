import React from 'react';
import PropTypes from 'prop-types';

import ModalStyled from './Modal.style';

const Modal = ({ children }) => {
  return (
    <ModalStyled role="dialog" tabIndex="-1">
      <div className="Modal__dialog">
        <div className="Modal__content">{children}</div>
      </div>
    </ModalStyled>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Modal;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cx from 'classnames';
import * as defaultTheme from '../variables';

const Heading = styled(({ className, theme, tag, full, ...rest }) => {
  const HeadingTag = `${tag}`;
  return <HeadingTag className={cx('Heading', className)} {...rest} />;
})`
  position: relative;
  margin: 0;
  padding-top: ${p => (p.size === 'xs' ? 14 : 24)}px;
  padding-bottom: ${p =>
    (p.size === 'xs' && 12) ||
    (p.size === 'sm' && 22) ||
    (p.size === 'lg' && 18) ||
    (p.size === 'xl' && 20)}px;
  color: inherit;
  font-family: inherit;
  font-size: ${p =>
    (p.size === 'xs' && 20) ||
    (p.size === 'sm' && 22) ||
    (p.size === 'lg' && 32) ||
    (p.size === 'xl' && 32)}px;
  font-weight: ${p => (p.size === 'xs' ? 700 : 900)};
  line-height: 1;
  text-align: left;
  letter-spacing: ${p =>
    (p.size === 'sm' && 1) ||
    (p.size === 'lg' && 2) ||
    (p.size === 'xl' && 3)}px;
  text-transform: uppercase;

  @media (${p => p.theme.smMin}) {
    font-size: ${p =>
      (p.size === 'xs' && 20) ||
      (p.size === 'sm' && 22) ||
      (p.size === 'lg' && 32) ||
      (p.size === 'xl' && 48)}px;
  }

  @media (${p => p.theme.lgMin}) {
    font-size: ${p =>
      (p.size === 'xs' && 20) ||
      (p.size === 'sm' && 24) ||
      (p.size === 'lg' && 48) ||
      (p.size === 'xl' && 64)}px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    background-color: currentColor;
  }

  &::before {
    top: 0;
    width: ${p => (p.full ? '100%' : '70px')};
    height: ${p => (p.full ? '5px' : '8px')};

    @media (${p => p.theme.smMin}) {
      width: ${p => (p.full ? '100%' : '110px')};
    }
  }

  &::after {
    bottom: 0;
    width: ${p => (p.full ? '100%' : '215px')};
    height: 2px;

    @media (${p => p.theme.smMin}) {
      width: ${p => (p.full ? '100%' : '234px')};
    }
  }

  small {
    display: block;
    margin-top: 8px;
    font-size: 47%;
    line-height: 1;
    letter-spacing: 1.3px;
  }
`;

Heading.propTypes = {
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  size: PropTypes.oneOf(['xs', 'sm', 'lg', 'xl']),
  full: PropTypes.bool,
};

Heading.defaultProps = {
  theme: defaultTheme,
  tag: 'h2',
  size: 'lg',
  full: false,
};

export default Heading;

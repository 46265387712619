import styled from 'styled-components';

const ModalRulesStyled = styled('div')`
  .ModalRules__desc {
    margin: 16px 0;
    padding: 20px;
    color: #000;
    background-color: #fff;
    font-size: 14px;
    overflow-x: hidden;

    @media (min-height: 414px) and (min-width: 768px) {
      max-height: 230px;
    }

    @media (min-height: 640px) and (min-width: 768px) {
      max-height: 420px;
    }

    p + p,
    p + ul,
    p + ol,
    ul + p {
      margin-top: 12px;
    }

    ol {
      padding-left: 14px;

      li + li {
        margin-top: 12px;
      }
    }

    ol ul {
      padding-left: 14px;
    }

    ol ul li {
      margin: 0;
    }

    a {
      color: #2683ef;

      &:hover {
        text-decoration: underline;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 10px;
    }

    u {
      text-decoration: underline;
    }

    table {
      border: 1px solid black;
      border-collapse: collapse;

      th,
      td {
        padding: 4px 8px;
        border: 1px solid black;
      }
    }

    hr {
      border-top: 1px solid #000;
    }
  }

  .ModalRules__links {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
  }

  .ModalRules__link {
    position: relative;
    width: auto;
    height: 22px;
    margin-right: 16px;
    margin-bottom: 8px;
    padding: 0;
    text-align: left;

    &::after {
      position: absolute;
      display: block;
      bottom: -4px;
      content: '';
      width: 100%;
      height: 2px;
      background-color: #000;
      opacity: 0;
      transition: 0.15s ease-in-out;
    }

    &.active::after {
      opacity: 1;
    }
  }

  .ModalRules__back {
    float: right;
  }
`;

export default ModalRulesStyled;

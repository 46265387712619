import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ModalRulesStyled from './ModalRules.style';

import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import ModalClose from '../ModalClose/ModalClose';

import RulesSweeps from './RulesSweeps';
import RulesAmPm from './RulesAmPm';
import RulesWalmart from './RulesWalmart';
import RulesCompetition from './RulesCompetition';

class ModalRules extends Component {
  constructor(props) {
    super(props);

    this.state = { activeRule: null, isActive: null };
  }

  componentDidMount() {
    this.onSwitchRule('sweeps');
  }

  onSwitchRule = rule => {
    const activeRule = this.getActiveRule(rule);

    this.setState({ activeRule, isActive: rule });
  };

  getActiveRule = rule =>
    ({
      sweeps: <RulesSweeps />,
      ampm: <RulesAmPm />,
      walmart: <RulesWalmart />,
      competition: <RulesCompetition />,
    }[rule]);

  render() {
    const { onClose } = this.props;
    const { activeRule, isActive } = this.state;

    return (
      <Modal>
        <ModalClose onClick={onClose} />
        <ModalRulesStyled>
          <Heading size="xs" full>
            Official rules
          </Heading>
          <div className="ModalRules__desc">
            <span>Quick Links</span>
            <br />
            <div className="ModalRules__links">
              <Button
                className={cx('ModalRules__link', {
                  active: isActive === 'sweeps',
                })}
                size="sm"
                variant="secondary"
                onClick={() => this.onSwitchRule('sweeps')}
              >
                Sweeps Rules
              </Button>
              <Button
                className={cx('ModalRules__link', {
                  active: isActive === 'walmart',
                })}
                size="sm"
                variant="secondary"
                onClick={() => this.onSwitchRule('walmart')}
              >
                Walmart Rules
              </Button>
              <Button
                className={cx('ModalRules__link', {
                  active: isActive === 'ampm',
                })}
                size="sm"
                variant="secondary"
                onClick={() => this.onSwitchRule('ampm')}
              >
                AMPM Rules
              </Button>
              <Button
                className={cx('ModalRules__link', {
                  active: isActive === 'competition',
                })}
                size="sm"
                variant="secondary"
                onClick={() => this.onSwitchRule('competition')}
              >
                Competition Rules
              </Button>
            </div>
            <hr />
            {activeRule}
          </div>
          <Button
            className="ModalRules__back"
            variant="secondary"
            onClick={onClose}
          >
            BACK
          </Button>
        </ModalRulesStyled>
      </Modal>
    );
  }
}

ModalRules.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalRules;

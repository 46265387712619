import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as defaultTheme from '../variables';
import { fadeIn, scaleIn, scaleOut } from '../animations';

const ModalStyled = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(41, 41, 41, 0.7);
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  animation-name: ${fadeIn};
  animation-duration: 0.3s;
  z-index: 2000;

  @media (${p => p.theme.smMin}) {
    padding: 0 15px;
  }

  .Modal__dialog {
    display: ${p => p.centered && 'flex'};
    align-items: ${p => p.centered && 'center'};
    min-height: 100%;
    margin-right: auto;
    margin-left: auto;

    @media (${p => p.theme.smMin}) {
      position: relative;
      max-width: ${p => !p.fullWidth && p.maxWidth[0]};
      min-height: ${p => p.centered && 'calc(100% - 60px)'};
      margin-top: 30px;
      margin-bottom: 30px;
    }

    @media (${p => p.theme.lgMin}) {
      max-width: ${p => !p.fullWidth && p.maxWidth[1]};
    }
  }

  .Modal__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #000;
    background-clip: padding-box;
    pointer-events: auto;
    animation-duration: 0.35s;
    animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);

    @media (${p => p.theme.xsMax}) {
      min-height: 100vh;
      padding: 75px 20px;
      animation-name: ${scaleIn};
    }

    @media (${p => p.theme.smMin}) {
      padding: 75px 70px;
      animation-name: ${scaleOut};
    }
  }
`;

ModalStyled.propTypes = {
  centered: PropTypes.bool,
  maxWidth: PropTypes.arrayOf(PropTypes.string),
  fullWidth: PropTypes.bool,
};

ModalStyled.defaultProps = {
  theme: defaultTheme,
  centered: true,
  maxWidth: ['600px', '1100px'],
  fullWidth: false,
};

export default ModalStyled;

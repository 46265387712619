import React from 'react';

const PrivacySlingshot = () => (
  <div>
    <div style={{ width: '100%', display: 'inline-block' }}>
      <img src="/assets/images/Destiny/slingshot-overview.png" alt="" />
    </div>
    <p>
      Privacy Policy:{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.polaris.com/en-us/company/privacy"
      >
        http://www.polaris.com/en-us/company/privacy
      </a>
    </p>

    <p>
      By submitting this form, you consent to receiving emails from each of
      Polaris Slingshot® and Polaris Sales Inc. relating to sales transactions,
      promotions and new products and services. You may unsubscribe at any time.
      Polaris Slingshot® and Polaris Sales Inc. will use your contact
      information in accordance with the Polaris Industries Inc. privacy policy
      and legal notice. This request for consent is made by Polaris Slingshot®,
      on its own behalf and on behalf of all affiliated entities and
      subsidiaries.
      <br />
      <br />
      2100 Minnesota 55
      <br />
      Medina, MN 55340
    </p>

    <p>
      Slingshot® is a three-wheeled motorcycle. It is not an automobile. It does
      not have airbags and it does not meet automotive safety standards. Three
      wheel vehicles may handle differently than other vehicles, especially in
      wet conditions. Always wear a DOT-approved full-face helmet and fasten
      seatbelts. The Driver may need a valid motorcycle endorsement. Don’t drink
      and drive.
    </p>

    <p>
      More Info:{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://slingshot.polaris.com/en-us/"
      >
        https://slingshot.polaris.com/en-us/
      </a>
    </p>
  </div>
);

export default PrivacySlingshot;

import React from 'react';
import PropTypes from 'prop-types';

import GlobalStyle from './GlobalStyle';
import LayoutStyled from './Layout.style';

const Layout = ({ children }) => (
  <LayoutStyled>
    <GlobalStyle />
    {children}
  </LayoutStyled>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

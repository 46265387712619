import styled from 'styled-components';
import * as defaultTheme from '../variables';

const FooterStyled = styled('footer')`
  color: ${p => p.theme.white};
  background-color: ${p => p.theme.black};
  overflow: auto;

  .Footer__inner {
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;
    padding: 30px 20px;

    @media (${p => p.theme.lgMin}) {
      padding: 30px 0;
    }
  }

  .Footer__rules {
    display: flex;
    align-items: center;

    > span {
      position: relative;
      top: -1px;
      display: block;
      width: 2px;
      height: 17px;
      margin: 0 8px;
      background-color: #979797;
    }
  }

  .Footer__rulesBtn {
    padding: 0;
    color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @media (min-width: 375px) {
      font-size: 14px;
    }
  }

  .Footer__desc {
    margin-top: 20px;
  }

  .Footer__logos {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin-top: 40px;
    list-style: none;

    @media (${p => p.theme.mdMin}) {
      justify-content: space-between;
    }
  }

  .Footer__logo {
    margin-bottom: 20px;
    vertical-align: top;

    @media (max-width: 519px) {
      width: 50%;
    }

    @media (min-width: 520px) {
      width: 33.3333%;
    }

    @media (min-width: 768px) {
      width: 25%;
    }

    @media (min-width: 1024px) {
      width: 125px;
    }

    &:nth-child(2n) > .Footer__logoLink {
      @media (max-width: 519px) {
        justify-content: flex-end;
      }
    }

    &:nth-child(3n + 2) > .Footer__logoLink {
      @media (min-width: 520px) and (max-width: 767px) {
        justify-content: center;
      }
    }

    &:nth-child(3n) > .Footer__logoLink {
      @media (min-width: 520px) and (max-width: 767px) {
        justify-content: flex-end;
      }
    }

    &:nth-child(4n + 2) > .Footer__logoLink,
    &:nth-child(4n + 3) > .Footer__logoLink {
      @media (min-width: 768px) and (max-width: 1279px) {
        justify-content: center;
      }
    }

    &:nth-child(4n) > .Footer__logoLink {
      @media (min-width: 768px) and (max-width: 1279px) {
        justify-content: flex-end;
      }
    }
  }

  .Footer__logoLink {
    display: flex;
    align-items: center;
    height: 80px;

    img {
      display: inline-block;
      width: 100%;
      max-width: 125px;
      max-height: 100px;
      height: auto;
    }
  }

  .Footer__powered {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1174px;
    margin: 0 auto;
    padding: 33px 0;
    border-top: 1px solid #4a4a4a;
  }

  .Footer__poweredLink {
    span {
      display: inline-block;
      margin-top: 9px;
      margin-right: 10px;
      color: inherit;
      font-size: 12px;
    }

    span,
    img {
      vertical-align: middle;
    }
  }

  .Footer__modals {
    & > .Modal:not(:last-child) {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

FooterStyled.defaultProps = {
  theme: defaultTheme,
};

export default FooterStyled;

import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faYoutube,
  faFacebookF,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import SocialStyled from './Social.style';

const Social = () => {
  return (
    <SocialStyled>
      <h2 className="Social__title">get the latest updates</h2>

      <div className="Social__inner">
        <div className="Social__block">
          <div className="Social__info d2">Experience More Game</div>

          <div className="Social__buttons">
            <a
              className="Social__link yt"
              href="https://www.youtube.com/user/destinygame/home"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a
              className="Social__link tw"
              href="https://twitter.com/destinythegame"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              className="Social__link fb"
              href="https://www.facebook.com/DestinyTheGame"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              className="Social__link ig"
              href="https://www.instagram.com/destinythegame/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>

        <div className="Social__block">
          <div className="Social__info rs">Go deeper with Rockstar</div>

          <div className="Social__buttons">
            <a
              className="Social__link yt"
              href="https://www.youtube.com/rockstar"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a
              className="Social__link tw"
              href="https://twitter.com/rockstarenergy"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              className="Social__link fb"
              href="https://www.facebook.com/rockstar"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              className="Social__link ig"
              href="https://www.instagram.com/rockstarenergy/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
      </div>
    </SocialStyled>
  );
};

export default Social;

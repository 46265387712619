import React from 'react';

const PrivacyRockstar = () => (
  <div>
    <p>
      This Privacy Policy constitutes an integral part of the Terms & Conditions
      that govern your use of the www.rockstarenergy.com website (“Site”), which
      is owned and operated by Rockstar, Inc. (“Rockstar”). This Privacy Policy
      also constitutes part of the terms and conditions (such as official rules)
      that govern your participation in any and all online and offline
      activities sponsored by Rockstar (each, a “Rockstar Sponsored Event”).
    </p>
    <p>
      Rockstar respects your online privacy. This Privacy Policy informs you of
      what information we may collect from or about you on our Site, how we may
      use such information, and your rights and choices regarding our use of
      your information. PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING
      OUR SITE. By using our Site or participating in a Rockstar Sponsored
      Event, you acknowledge that you have read this Privacy Policy, and agree
      to be bound by it. If you do not agree to any of the terms contained in
      this Privacy Policy, please refrain from using our Site or disclosing
      personal information on our Site or participating in any Rockstar
      Sponsored Event.
    </p>
    <p>
      Notice to California Residents – Your California Privacy Rights. We may
      share customer information with non-affiliated parties so that they may
      offer, market and advertise products and services directly to our
      customers. Pursuant to California Civil Code Section 1798.83, you may opt
      out of having your personal information shared with third parties at no
      charge by sending us an email at optout.nonaffiliate@rockstarenergy.com
      which must include your request, including your name, phone number, and
      email address. You may also request any information concerning you
      previously disclosed by Rockstar by sending an email to
      privacy@rockstarenergy.com which must include the same information. Please
      note that Rockstar is not required to respond to such requests regarding
      disclosed information more than once in a calendar year, nor are we
      required to respond to any request which is not sent to the email address
      designated above.
    </p>
    <h4>1. TYPES OF INFORMATION WE COLLECT</h4>
    <p>
      (a) Personal Information. This refers to your personally identifiable
      information, which may include your first and last name, telephone number,
      cellular number, text number, email address, social media account
      information for Instagram, Twitter, Facebook, or mailing address (as
      defined below). Many areas and features on our Site do not require
      registration and may be accessed by you without having to disclose any
      Personal Information. However, to the extent you voluntarily provide your
      Personal Information to us, we collect such information. For example, we
      collect Personal Information from you: (i) when you register to become a
      member on our Site; (ii) when you register to participate in a Rockstar
      Sponsored Event; (iii) when you join our electronic mailing list.
    </p>
    <p>
      (b) Aggregate and Anonymous Information. This refers to any information
      that does not identify you individually. For example, we collect aggregate
      various traffic data regarding our Site. We may also combine “User
      Activity Information” (as described below) about you and other users of
      our Site to generate aggregate information that reflects activities,
      habits, preferences, interests, etc. about our users as a group. In
      addition, we may take Personal Information and make it non-personally
      identifiable, such as by aggregating your information with information
      about other individuals, or by removing personally-identifiable elements
      (such as names) so as to anonymize your information.
    </p>
    <p>
      (c) User Activity Information. This refers to information we collect about
      user activities on our Site. For example, our Site servers automatically
      collect and log clickstream data for all visitors to our Site, such as IP
      addresses, page requests, pages visited, content viewed, clicks and search
      queries made, etc. We also use cookies and web beacons to collect user
      activity information on our Site.
    </p>
    <p>
      To the extent we use User Activity Information in a manner that identifies
      a specific user individually, it will be treated as Personal Information.
      Otherwise, User Activity Information will be treated as Aggregate and
      Anonymous Information.
    </p>
    <h4>2. OUR USE OF INFORMATION</h4>
    <p>
      Rockstar uses information collected from and/or about our users and
      customers to improve our products and services, to improve the pages and
      content of our Site, to administer and maintain operations of our Site, to
      manage relationships with our users and customers, to offer, market and
      advertise products and services to our users and customers, and to conduct
      market research and analysis.
    </p>
    <p>
      (a) For Marketing and Advertising Purposes. We may use your Personal
      Information and/or User Activity Information to offer, market and
      advertise to you, on our Site and/or via email, our and/or third-party
      products and services that may be of interest to you.
    </p>
    <p>
      (b) For Market Research Purposes. We may use Aggregate and Anonymous
      Information (including such information derived from Personal Information
      and User Activity Information) to conduct market research and analysis for
      ourselves as well as for our sponsors and business partners.
    </p>
    <h4>3. DISCLOSURE TO THIRD PARTIES OUTSIDE ROCKSTAR</h4>
    <p>
      (a) Disclosure to Non-Affiliates for Direct Marketing/Advertising
      Purposes. We do not, but may in the future, share your Personal
      Information with non-affiliated third parties so that they may offer,
      market and advertise products and services directly to you.
    </p>
    <p>
      (b) Disclosure to Co-Sponsors. From time to time, we may co-sponsor
      (online and offline) events and/or product/service offerings with
      non-affiliated third parties. If you participate in such co-sponsored
      events or offerings, any Personal Information and User Activity
      Information we collect from you based on your participation may be shared
      with our co-sponsors who may use such information for their own marketing
      and advertising purposes. You must contact our co-sponsors directly in
      order to opt out of their use of your information for marketing and
      advertising purposes.
    </p>
    <p>
      (c) Disclosure to Service Providers. We may disclose your Personal
      Information and User Activity Information to our outside vendors and
      contractors who need to access such information in order to perform their
      services (including without limitation survey/contest/sweepstakes
      administration and prize fulfillment, data research and analysis, data
      collection and processing, data storage, data security, hosting and
      technical support for our Site, and marketing and advertising support) to
      us (collectively, “Service Providers”). We require that our Service
      Providers use your information shared by us solely for the purposes of
      performing their services to us and that they maintain the
      confidentiality, security and integrity of such information and not
      further disclose the information to others.w
    </p>
    <p>
      (d) Disclosure Under Other Circumstances. We may disclose your Personal
      Information and User Activity Information to others if doing so is
      required by law, or in our good faith belief, is reasonably necessary to
      comply with any legal process, law enforcement, to enforce this Privacy
      Policy, respond to an emergency, or protect the rights, property or safety
      of Rockstar, and/or the public.
    </p>
    <p>
      (e) Unrestricted Disclosure of Aggregate and Anonymous Information. Given
      the anonymous, non-personally identifiable nature of such information,
      there are no restrictions under this Privacy Policy on how we may use or
      disclose such information. For example, we may freely share such
      information with non-affiliated third parties who may use such data for
      their own marketing, advertising, research, or other business purposes. We
      may also freely share such information with our Service Providers in order
      for them to perform services for us.
    </p>
    <h4>4. INFORMATION DISCLOSURE: YOUR CHOICES</h4>
    <p>
      (a) Opt-Out for Email Marketing. When you register to become a member on
      our Site or to participate in a Rockstar Sponsored Event, we may provide
      you with the opportunity to opt out of receiving marketing emails from us
      at that time. Even if you do not exercise the opt-out choice when you
      disclose your Personal Information to Rockstar, you may subsequently
      exercise such choice at any time, by sending your request to us via email
      at optout.marketing@rockstarenergy.com, or by using the “Unsubscribe” link
      provided in a marketing email you may have previously received from us.
      Please note, however, that your opt-out will apply only to marketing and
      promotional emails from us and will not stop email communications from us
      relating to your Transaction or Request, such as notice of a material
      change in this Privacy Policy and/or the Terms and Conditions for our
      Site, notice of an actual or suspected security breach that affects your
      Personal Information stored by or for us, etc.
    </p>
    <p>
      Please also note that if the email address or text/mobile number you
      provide to us is a “wireless” email address (i.e. the address is
      designated by a wireless service carrier specifically for transmitting
      emails to a wireless device), we will not send marketing emails to that
      address without your prior opt-in consent. The FCC has established a list
      of domain names used by wireless service providers to transmit emails to
      wireless devices at{' '}
      <a
        href="www.fcc.gov/cgb/policy/DomainNameDownload.html"
        target="_blank"
        rel="noreferrer noopener"
      >
        http://www.fcc.gov/cgb/policy/DomainNameDownload.html
      </a>{' '}
      Wireless service providers must add new domain names to this list within
      30 days of activating them. If your email address contains a domain name
      on this list (after the “@” symbol), then we will treat your email address
      as a wireless email address. However, the mere fact that you can retrieve
      emails from your regular Internet email account on your mobile/wireless
      device does not make your Internet email address a “wireless” email
      address.
    </p>
    <p>
      (b) Opt-Out for Disclosure to Non-Affiliates for Direct
      Marketing/Advertising Purposes. We do not currently, but may in the
      future, share your Personal Information with non-affiliated third parties
      so that they may offer, market and advertise products and services
      directly to you. You may at any time opt out of such information sharing,
      by sending your request to us via email at
      optout.nonaffiliate@rockstarenergy.com. We may also provide you with an
      opportunity to opt out at the time you register for a Rockstar Sponsored
      Event, such as by checking an opt-out box on the registration page.
    </p>
    <h4>5. HOW WE PROTECT YOUR INFORMATION</h4>
    <p>
      Rockstar understands the importance of protecting the security and
      integrity of personal information that our customers have shared with us
      and will endeavor to safeguard your Personal Information. While Rockstar
      will attempt to safeguard your Personal Information, it cannot guarantee
      the absolute security of such information. You understand and agree that
      Rockstar shall not be liable for any breach of the security of your
      Personal Information resulting from causes or events that are beyond
      Rockstar’s control, including, without limitation, your own act or
      omission, corruption of storage media, defects in third-party data
      security products or services, power failures, natural phenomena, riots,
      acts of vandalism, hacking, sabotage, or terrorism. Please note that any
      information that you post in any public, community or interactive areas on
      our Site (e.g. chat rooms, bulletin boards, message boards, and discussion
      groups) will be accessible to, and may be collected and used by, others
      and may result in unsolicited or unwanted messages or contact from others.
    </p>
    <h4>6. USE OF COOKIES</h4>
    <p>
      We may send cookies, which are small pieces of data, to your web browser
      to facilitate your use of the Site. Cookies help us deliver content
      specific to your interests and permit our servers to recall information
      from prior to visits to our Site. We may use information collected from
      cookies together with Personal Information we have collected from you. We
      do not use cookies to access information on your computer or device. You
      can choose whether to accept cookies when first visiting our site, also by
      adjusting the settings of your browser. If your browser is set to reject
      cookies, you may still enter our Site, but you may not have full access to
      all areas within our Site.
    </p>
    <h4>7. LINKS TO THIRD-PARTY WEBSITES</h4>
    <p>
      Our Site may contain links to third-party websites that are now owned or
      controlled by or affiliated with Rockstar. Rockstar is not responsible for
      the privacy practices of such third-party sites. Once you enter such a
      third-party site, this Privacy Policy will no longer apply, and any
      information collected from or about you on that third-party site will be
      governed by the privacy policy of that third party. Such third-party
      sites’ privacy policies and practices may be substantially different from
      those of Rockstar. They may send their own cookies to you and may collect
      data about you and make use of that data in ways that we would not. You
      access such third-party sites entirely at your own risk. You should always
      read the privacy policy for a third-party site before disclosing any
      personal information on such site.
    </p>
    <h4>8. PROTECTING CHILDREN UNDER 13</h4>
    <p>
      Our Site is not designed or intended for children under the age of 13, and
      we do not knowingly collect personal information from children under 13 on
      our Site. Persons under the age of 13 are prohibited from registering on
      our Site. If we discover that any registration is made by a person under
      13, such registration will be immediately and permanently canceled and
      removed from our Site, with or without notice.
    </p>
    <h4>9. CHANGES TO PRIVACY POLICY</h4>
    <p>
      We reserve the right to modify this Privacy Policy from time to time in
      our sole discretion. For example, we may amend this Privacy Policy in
      order to address new developments in federal and state consumer privacy
      laws, or to conform to changing industry practices. If we make changes to
      this Privacy Policy, we will announce and post such changes on our Site
      for the general public. If you are a registered user, we may also notify
      you of the changes via email. By continuing to use our Site after such
      notice, you are bound by this Privacy Policy as modified.
    </p>
    <h4>10. OUR CONTACT INFORMATION</h4>
    <p>
      Should you have questions about this Privacy Policy or our information
      collection, use and disclosure practices, you may contact us at
      privacy@rockstarenergy.com. We will use reasonable efforts to respond
      promptly to requests, questions or concerns you may have regarding our use
      of Personal Information about you.
    </p>
  </div>
);

export default PrivacyRockstar;

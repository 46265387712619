import React from 'react';

const RulesCompetition = () => (
  <div>
    <p>
      <strong>ROCKSTAR INC.</strong>
    </p>
    <p>
      <strong>Rockstar Riots Destiny 2: Forsaken Game Competition</strong>
    </p>
    <p>
      <strong>OFFICIAL RULES</strong>
    </p>
    <p>
      <strong>
        To participate in the Rockstar Riots Destiny 2: Forsaken Game
        Competition (also referred to as the “Contest” or “Promotion”), you must
        agree to and abide by these Official Rules.
      </strong>
    </p>
    <p>
      <strong>
        ACCESS TO VALID SUBSCRIPTION TO PLAYSTATION® OR XBOX IS REQUIRED TO BE
        ELIGIBLE TO ENTER.
      </strong>
    </p>
    <p>
      <strong>
        GRAND PRIZE REQUIRES AIR/GROUND TRAVEL TO BELLEVUE, WASHINGTON, USA ON
        DECEMBER 16-19, 2018. ALL ENTRANTS MUST HAVE A VALID PASSPORT, VISA AND
        ANY OTHER REQUIRED TRAVEL DOCUMENTATION TO BE ELIGIBLE TO CLAIM THE
        GRAND PRIZE.
      </strong>
    </p>
    <p>
      <strong>
        PROMOTION IS OPEN TO LEGAL RESIDENTS OF THE 50 UNITED STATES AND THE
        DISTRICT OF COLUMBIA (EXCLUDING RESIDENTS OF AZ, CO, CT, ND, NE AND MD),
        CANADA, THE UNITED KINGDOM AND GERMANY, Who aRE AT LEAST 18 YEARS OF
        AGE, or the AGE OF MAJORITY IN THEIR STATE/PROVINCE OF RESIDENCE,
        WHICHEVER IS OLDER, AT TIME OF ENTRY. VOID ELSEWHERE AND WHERE
        PROHIBITED OR RESTRICTED BY LAW. INTERNET ACCESS, VALID E-MAIL ADDRESS,
        DESTINY 2 GAME AND SUBSCRIPTION TO EITHER PLAYSTATION® PLUS (FOR PS4
        USERS) OR XBOX LIVE GOLD (FOR XBOX ONE™ USERS) REQUIRED PRIOR TO OCTOBER
        5, 2018. DESTINY 2: FORSAKEN EXPANSION REQUIRED FOR CERTAIN CONTEST
        ELEMENTS. VOID IN AZ, CO, CT, ND, NE AND MD.
      </strong>
    </p>
    <p>
      <strong>
        PLAYSTATION®, XBOX, ACTIVISION, BUNGIE, IBUYPOWER, VERTAGEAR, ASTRO
        GAMING AND SCUF, ARE NOT SPONSORS OF OR OTHERWISE ASSOCIATED WITH OR
        INVOLVED IN OFFERING OR ADMINISTRATING THIS CONTEST; THEIR TRADEMARKS
        ARE USED HEREIN SOLELY FOR REFERENCE PURPOSES.
      </strong>
    </p>

    <ol>
      <li>
        <p>
          <strong>Sponsor:</strong> Rockstar Inc., 101 Convention Center Dr.,
          Suite 777, Las Vegas, NV 89109 USA <strong>(“Sponsor”)</strong>.{' '}
          Neither Activision Publishing, Inc. nor Bungie, Inc. are sponsors of
          this Contest.
        </p>
      </li>
      <li>
        <p>
          <strong>Administrator:</strong> Brandmovers, Inc., 590 Means Street,
          Ste 250, Atlanta, GA 30318, USA <strong>(“Administrator”)</strong>.
        </p>
      </li>
      <li>
        <p>
          <strong>Eligibility:</strong> The Contest is open only to legal
          residents of the 50 United States and the District of Columbia (but
          <strong>excluding</strong> residents of AZ, CO, CT, ND, NE and MD),
          Canada, the United Kingdom and Germany, who are at least 18 years of
          age or the age of majority in their state/province of legal residence
          at time of entry (whichever is older) and who{' '}
          <strong>prior to</strong> October 5, 2018 have Internet access, a
          valid e-mail address, and the Destiny 2 game for PS4, Xbox One™ or PC,
          access to a paid subscription to either Playstation® Plus (for PS4
          users) or Xbox Live Gold (for Xbox users) (no subscription required
          for PC users), and an online account with Bungie.net. Destiny 2:
          Forsaken required for certain Contest elements. Void in AZ CO, CT, ND,
          NE and MD, and elsewhere and where prohibited or restricted by law.
          Any individuals (including but not limited to employees, consultants,
          independent contractors, and interns) who have, within the past six
          months, performed services for Sponsor, Administrator, Activision,
          Bungie, iBUYPOWER, VERTAGEAR, ASTRO GAMING, SCUF, or any organizations
          responsible for sponsoring, fulfilling, administering, advertising or
          promoting the Contest or supplying the prizes, persons involved in the
          creation, development or production of Destiny 2 Game and/or their
          respective parent, subsidiary, affiliated and successor companies, and
          immediate family and household members of such individuals, are not
          eligible to participate or win any prize.{' '}
          <strong>“Immediate family members”</strong> shall mean parents,
          step-parents, children, step-children, siblings, step-siblings, or
          spouses, regardless of where they live.{' '}
          <strong>“Household members”</strong> shall mean people who share the
          same residence at least three (3) months a year, whether related or
          not. Potential winners may be required to provide proof of legal
          residency upon request prior to prize award. Entrants are only
          permitted to enter the Contest using their own online account with
          Bungie.net and are prohibited from entering using multiple online
          accounts with Bungie.net.
        </p>
        <p>
          <strong>
            GRAND PRIZE REQUIRES AIR/GROUND TRAVEL TO BELLEVUE, WASHINGTON, USA
            ON DECEMBER 16-19, 2018. ALL ENTRANTS MUST HAVE A VALID PASSPORT,
            VISA AND ANY OTHER REQUIRED TRAVEL DOCUMENTATION TO BE ELIGIBLE TO
            CLAIM THE GRAND PRIZE.
          </strong>
        </p>
      </li>
      <li>
        <p>
          <strong>How to Play:</strong> The Contest begins at 12:00:00 am United
          States Eastern Time <strong>(“ET”)</strong> on October 7, 2018 and
          ends at 11:59:59 pm ET on December 1, 2018{' '}
          <strong>(“Contest Period”)</strong>. Sponsor’s computer is the
          official clock for this Contest. It is each entrant’s responsibility
          to ensure he or she is aware of any time difference in the entrant’s
          location. To enter, visit{' '}
          <a href="https://destiny2.rockstarenergy.com">
            Destiny2.Rockstarenergy.com
          </a>{' '}
          <strong>(“Website”)</strong> during the Contest Period. Participants
          will register with their Bungie.net account, and make the following
          selections for entry into the Contest: (ii) country of participation;
          (ii) guardian game character; and (iii) game platform (i.e., PS4, Xbox
          One or PC). Once made, all selections are final and cannot be changed
          at any time during the Contest Period.
        </p>
        <p>
          The Contest Period is divided into individual Game Weeks, as specified
          below. During each Game Week, start the <strong>Destiny 2</strong>{' '}
          game <strong>(“Game”)</strong>, connect to <strong>Bungie.net</strong>
          , and play the Game mode allocated to the relevant Game Week as
          specified below (each, a <strong>“Mode”</strong> or collectively,{' '}
          <strong>“Modes”</strong>
          ). In each Game Week, the following points <strong>
            (“Points”)
          </strong>{' '}
          are available:
        </p>
        <p>
          <u>OPEN ROUND</u>
        </p>
        <p>
          <strong>Game Week 1: Crucible Mode</strong> (October 7 - October 13)
        </p>
        <ul>
          <li>100 points for completion</li>
          <li>1 point per Guardian defeated</li>
          <li>3 points per Guardian killed with precision</li>
        </ul>
        <p>
          <strong>Game Week 2: Strikes Mode</strong> (October 14 - October 20)
        </p>
        <ul>
          <li>125 points for completion</li>
          <li>1 point per Enemy kill</li>
          <li>3 points per Enemy precision kill</li>
        </ul>
        <p>
          <strong>Game Week 3: Gambit Mode</strong> (October 21 - October 27)
          (Destiny 2: Forsaken required)
        </p>
        <ul>
          <li>150 points for completion</li>
          <li>3 points per Invader defeated</li>
          <li>1 point per enemy killed</li>
          <li>5 points per kills of an invader with precision</li>
        </ul>
        <p>
          <u>LEGENDARY ROUND</u>
        </p>
        <p>
          <strong>Game Week 4: Crucible Mode</strong> (October 28 - November 3)
        </p>
        <ul>
          <li>100 points for completion</li>
          <li>1 point per Guardian defeated</li>
          <li>3 points per Guardian killed with precision</li>
        </ul>
        <p>
          <strong>Game Week 5: Strikes Mode</strong> (November 4 - November 10)
        </p>
        <ul>
          <li>125 points for completion</li>
          <li>1 point per Enemy kill</li>
          <li>3 points per Enemy precision kill</li>
        </ul>
        <p>
          <strong>Game Week 6: Gambit Mode</strong> (November 11 - November 17)
          (Destiny 2: Forsaken required)
        </p>
        <ul>
          <li>150 points for completion</li>
          <li>3 points per Invader defeated</li>
          <li>1 point per enemy killed</li>
          <li>5 points per kills of an invader with precision</li>
        </ul>
        <p>
          The objective of the Contest is to earn as many Points as possible
          during each Game Week. Points accrued in one Game Week do not rollover
          to the next Game Week. All entrants will start each Game Week with
          zero Points. Your Points will be posted to the LEADERBOARD (as
          described below). You may play the Game (compete in the Contest) as
          often as you like during the Contest Period.{' '}
          <strong>
            POINTS CAN ONLY BE EARNED IN EACH GAME WEEK BY PLAYING THE MODE
            ALLOCATED TO THAT GAME WEEK. IF YOU PLAY A DIFFERENT MODE, YOU WILL
            NOT EARN ANY POINTS WHILST PLAYING THAT INELIGIBLE MODE. POINTS
            EARNED IN PRIVATE MATCHES WITH FEWER THAN FOUR PARTICIPANTS DO NOT
            QUALIFY FOR THE CONTEST.
          </strong>
        </p>
        <p>
          In participating in the Contest, you must comply with the terms and
          conditions of use/community guidelines of your online gaming provider
          at all times. Your participation is also conditional on your full
          compliance with the Bungie, Inc. Software License Agreement and
          associated Code of Conduct, which are available
          https://www.bungie.net/en/Bungie/SLA.
        </p>
      </li>
      <li>
        <p>
          <strong>LEADERBOARD:</strong> The LEADERBOARD posted on the Contest
          site{' '}
          <a href="https://destiny2.rockstarenergy.com">
            Destiny2.Rockstarenergy.com
          </a>{' '}
          <strong>(“Website”)</strong> is intended to reflect the Points of the
          highest ranked entrants during the Contest Period. Every reasonable
          effort will be made by Sponsor to maintain and accurately reflect the
          highest number of Points that entrants have earned as well as their
          rankings as compared to other entrants. However, only the top 200
          entrants will appear on the LEADERBOARD. The positions on the
          LEADERBOARD will be determined according to how many Points each
          entrant has achieved, with the highest-scoring entrant being placed at
          the top of the LEADERBOARD and the remaining entrants being placed in
          descending order. Entrants who achieve the same Points score in any
          Game Week will have their positions on the LEADERBOARD determined
          according to how quickly they achieved their Points total in that Game
          Week, so that an entrant who achieved 1,000 Points by midday on
          Wednesday will appear higher on the LEADERBOARD than a player who
          achieved 1,000 points two hours later. For the purposes of determining
          the positions, each entrant’s time will be rounded to the nearest
          tenth of a second. Sponsor and its agencies (including Administrator)
          shall not be responsible for technical errors out of its control which
          may cause inaccurate or incomplete information to be reflected at any
          given time. Sponsor may update the LEADERBOARD at time(s) of its sole
          choosing and Sponsor may correct erroneous listings on LEADERBOARD at
          any time in its sole discretion without liability of any kind,
          including without limitation, in response to violations by
          participants of the Bungie, Inc. Software License Agreement and
          associated Code of Conduct, which are grounds for disqualification.
          Notwithstanding listings on the LEADERBOARD, a person is not a
          potential prize winner unless and until he/she is notified personally
          by Sponsor or its agency to such effect (see Official Rule #7 below).
          Entrants should maintain their own records of their participation in
          the Contest. For the avoidance of doubt, other leaderboards not
          published/disseminated by Sponsor (e.g., any leaderboard available via
          an online gaming platform) are not in any way sanctioned by Sponsor
          and will NOT be recognized as proof of an entrant’s performance in the
          Contest. The liability of Sponsor and its agencies (including
          Administrator) remains unaffected.
        </p>
      </li>
      <li>
        <p>
          <strong>ROUNDS/WINNER DETERMINATION:</strong> There will be three (3)
          rounds during the Contest Period which will determine the winners
          (each, a <strong>“Round”</strong>
          ), as follows:
        </p>
        <p>
          <strong>
            <u>OPEN ROUND:</u>
          </strong>{' '}
          The first Round will consist of three (3) Game Weeks, beginning at
          12:00:00 am ET on October 7, 2018 and ending at 11:59:59 pm ET on
          October 27, 2018 (the <strong>“Open Round”</strong>
          ). At around 9:00 am ET on each Monday morning following the end of a
          Game Week in the Open Round, Administrator will verify the accuracy of
          all eligible entrants’ Points earned in the previous Game Week,
          pursuant to Official Rule #4 above. The top ten (10) placed entrants
          on the LEADERBOARD at the end of each Game Week will win an Open Round
          Prize. The first-placed entrant on the LEADERBOARD will receive an
          Open Round Grand Prize, an Open Round First Prize and an Open Round
          Second Prize. Entrants placed second (2nd) to fifth (5th) on the
          LEADERBOARD will receive an Open Round First Prize and an Open Round
          Second Prize. Entrants placed sixth (6th) to tenth (10th) on the
          LEADERBOARD will receive an Open Round Second Prize. All potential
          prize winners will be confirmed each Monday during the Open Round by
          Administrator whose decisions (along with those of Sponsor) are final
          and binding on all matters related to the Contest. Sponsor reserves
          the right to select fewer than the stated number of winners for any
          prize level in the event that the total number of eligible entrants in
          each Game Week of the Open Round of the Contest is fewer than the
          number of available prizes for that Game Week.
        </p>
        <p>
          <strong>
            <u>LEGENDARY ROUND:</u>
          </strong>{' '}
          The second Round will consist of three (3) Game Weeks, beginning at
          12:00:00 am ET on October 28, 2018 and ending at 11:59:59 pm ET on
          November 17, 2018 (the <strong>“Legendary Round”</strong>
          ). At around 9:00 am ET on each Monday morning following the end of a
          Game Week in the Legendary Round, Administrator will verify the
          accuracy of all eligible entrants’ Points earned in the previous Game
          Week, pursuant to Official Rule #4 above. The top ten (10) placed
          entrants on the LEADERBOARD at the end of each Game Week will win a
          Legendary Round Prize. The first-placed entrant on the LEADERBOARD
          will receive a Legendary Round Grand Prize, a Legendary Round First
          Prize and a Legendary Round Second Prize. Entrants placed second (2nd)
          to fifth (5th) on the LEADERBOARD will receive a Legendary Round First
          Prize and a Legendary Round Second Prize. Entrants placed sixth (6th)
          to tenth (10th) on the LEADERBOARD will receive a Legendary Round
          Second Prize. The top 200 placed entrants on the LEADERBOARD each Game
          Week during the Legendary Round (including the top ten (10) placed
          entrants) will win a Qualifier Prize, and will qualify to participate
          in the Qualifier Round (each a <strong>“Qualifier”</strong>
          ). A maximum of 600 Qualifiers will progress from the Legendary Round
          to the Qualifier Round. All potential prize winners will be confirmed
          each Monday during the Legendary Round by Administrator whose
          decisions (along with those of Sponsor) are final and binding on all
          matters related to the Contest. Sponsor reserves the right to select
          fewer than the stated number of Qualifiers or winners for any prize
          level in the event that the total number of eligible entrants in each
          Game Week of the Legendary Round of the Contest is fewer than the
          number of available prizes for that Game Week.
        </p>
        <p>
          <strong>
            <u>QUALIFIER ROUND:</u>
          </strong>{' '}
          The third Round will begin at 12:00:00 am ET on November 25, 2018 and
          end at 11:59:59 pm ET on December 1, 2018 (the “Qualifier Round”). At
          or about 9:00 am ET on December 2, 2018, Administrator will verify the
          accuracy of all eligible Qualifiers’ Points earned during the
          Qualifier Round, pursuant to Official Rule #4 above, and the top four
          (4) Qualifiers on the LEADERBOARD will win a Grand Prize. All
          potential Grand Prize winners will be confirmed on or about December
          19, 2018 by Administrator whose decisions (along with those of
          Sponsor) are final and binding on all matters related to the Contest.
        </p>
      </li>
      <li>
        <p>
          <strong>
            Winner Notification & Verification and prize delivery:
          </strong>{' '}
          Potential prize winners will be notified by e-mail and/or phone using
          the contact information used by such prize winner to register on
          Bungie.net. Winners will be notified within 3 days of the end of each
          [Game Week/Round]. Potential prize winners will have{' '}
          <strong>five (5) days</strong> to respond to the initial notification
          by e-mail/phone. Failure to respond to the initial verification
          e-mail, or failure to provide the information and/or documentation
          requested to claim the prize, within <strong>five (5) days</strong>{' '}
          after notification or return of e-mail notification as undeliverable
          after two (2) attempts will result in disqualification. Entrants are
          solely responsible for providing accurate contact information at the
          time of entry and for monitoring their inboxes (including spam and
          junk mail folders). Potential prize winners may be required to sign
          and return within <strong>five (5) days</strong> of notification an
          Affidavit of Eligibility/Declaration (if Canadian Resident), Release
          and Indemnification, a tax acknowledgment form (if applicable) and a
          Publicity Release where allowed by law. If the Affidavit of
          Eligibility/Declaration, Release and Indemnification, tax
          acknowledgment form (if applicable) and Publicity Release (where
          allowed by law) are not returned to Sponsor within the specified time
          period (should the signing of such documents be required), a potential
          winner may be disqualified at Sponsor’s sole discretion. If a
          potential winner is disqualified for any reason, the potential
          winner’s prize will be forfeited without compensation of any kind and
          will be awarded to the participant with the next highest score on the
          LEADERBOARD, as per Rule #6. Prior to awarding any prize, Sponsor
          reserves the right to require any potential prize winner to provide
          evidence of eligibility to Sponsor’s reasonable satisfaction.
        </p>
        <p>
          The prizes awarded in the Open Round and Legendary Round will be sent
          to each winner within sixty (60) days of Sponsor’s receipt of the
          winner’s valid prize claim.
        </p>
        <p>
          Grand Prize winners will be contacted by Administrator to arrange
          travel to the Live Finals Event within 3 days of Sponsor’s receipt of
          the winner’s valid prize claim.
        </p>
      </li>
      <li>
        <p>
          <strong>Prizes, Approximate Retail Values (“ARV”):</strong> The total
          ARV of all Prizes is $47,725.00. If the actual retail value of any
          prize or prize component differs from the stated ARV, the relevant
          winner will not be entitled to receive any difference in value and
          Sponsor will not award any such difference.
        </p>
        <p>
          <strong>Open Round Prizes:</strong>
        </p>
        <p>
          <strong>One (1) Open Round Grand Prize</strong> – A Vertagear Game
          Chair. ARV: $450.00. <strong>Five (5) Open Round First Prizes</strong>{' '}
          – Riots themed Headset and Controller. ARV: $450.00 each.{' '}
          <strong>Ten (10) Open Round Second Prizes</strong> – A $50.00
          Bungie.com Store Gift Card and two (2) cases of Rockstar Energy Drink.
          ARV: $110.00 each.
        </p>
        <p>
          <strong>Legendary Round Prizes:</strong>
        </p>
        <p>
          <strong>One (1) Legendary Round Grand Prize</strong> – A custom IBP PC
          Rig Bundle. ARV: $4,005.00.{' '}
          <strong>Five (5) Legendary Round First Prizes</strong> – Themed Game
          Chair, Headset and Controller. ARV: $1,005.00 each.{' '}
          <strong>Ten (10) Legendary Round Second Prizes</strong> – Destiny 2
          Alexa Ghost and two (2) cases of Rockstar Energy Drink. ARV: $155.00
          each. <strong>Two hundred (200) Qualifier Prizes</strong> – A Destiny
          2 Themed Hat & Hoodie. ARV: $65.00 each.
        </p>
        <p>
          <strong>Qualifier Round Prizes:</strong>
        </p>
        <p>
          <strong>Four (4) Grand Prizes</strong> – A trip for the Grand Prize
          winner plus one (1) adult guest (at least 18 years of age or the age
          of majority in the guest’s state/province of residence, whichever is
          older) to the Bungie Studios in Bellevue, Washington, USA to compete
          with the Bungie Team, live via Twitch Stream, in the “Live Finals
          Event @ Bungie” on December 18, 2018. Trip includes: roundtrip airfare
          from major airport nearest winner’s residence to a Sponsor-specified
          airport in Washington, USA; three (3) nights’ hotel accommodations at
          a Sponsor-specified hotel in or near Bellevue, WA (one standard class
          room, double occupancy); and entry into the Live Finals Event @ Bungie
          to compete with the Bungie team. ARV: $5,000.00 each.
        </p>
        <p>
          <strong>
            GRAND PRIZE REQUIRES AIR/GROUND TRAVEL TO BELLEVUE, WASHINGTON, USA
            ON DECEMBER 16-19, 2018. ALL ENTRANTS MUST HAVE A VALID PASSPORT,
            VISA AND ANY OTHER REQUIRED TRAVEL DOCUMENTATION TO BE ELIGIBLE TO
            CLAIM THE GRAND PRIZE.
          </strong>
        </p>
        <p>
          <strong>
            Winner of Grand Prize and guest must depart from their home airport
            on December 16, 2018, and take the return flight home on December
            19, 2018,
          </strong>{' '}
          or the Grand Prize will be forfeited and awarded to an alternate
          winner, time permitting. Winner and guest must travel together. All
          airline tickets are subject to the vagaries of flight variation, work
          stoppages, and schedule or route changes. Winner must comply with any
          applicable hotel check-in requirements, such as presentation of a
          major credit card. Room taxes and other hotel fees may not be included
          and if applicable may be payable at the time of check-out by the
          winner. All expenses and incidental travel costs, which may include
          but not be limited to, ground transportation, gratuities, incidentals,
          passenger tariffs or duties, baggage fees, surcharges, airport fees,
          service charges or facility charges, personal charges at lodging,
          security fees, taxes or other expenses not specifically stated as
          being included in the prize are the sole responsibility of the Grand
          Prize winner. Winner is responsible for arranging and paying for
          travel to and from the departure and arrival airports. In the event
          that a Grand Prize winner resides within 150 miles from Bellevue,
          Washington, Sponsor reserves the right to provide ground
          transportation in lieu of airfare and any difference between the
          stated ARV and actual ARV will not be awarded. The winner and guest
          must comply fully with the terms and conditions (including all health
          and safety requirements) of all carriers, hotels and other venues used
          or attended in connection with the redemption of the Grand Prize.
          Sponsor shall have no liability to the winner or guest if the winner
          and/or guest are denied entry to or use of, or are asked to leave, any
          transport, hotel or other venue as a result of their breach of such
          terms or any applicable law.
        </p>
      </li>
      <li>
        <p>
          <strong>Additional Prize Restrictions:</strong> All prizes are subject
          to the warranties and guarantees from that product’s manufacturer.
          Prizes are non-transferable, non-substitutable and may not be redeemed
          or exchanged for cash, except with Sponsor’s permission (in its sole
          discretion). Prizes are subject to availability. Sponsor in its sole
          discretion may award a substitute prize or prize component of equal or
          greater value if advertised prize/prize component is unavailable at
          time of awarding for any reason. Any images of prizes/prize components
          in advertising are for illustrative purposes only and actual items
          awarded as prizes/prize components may differ from such images. Each
          prize must be accepted by winner with all prize components (as
          described above). National, federal, state, provincial, and local
          taxes are the sole responsibility of winners. Winners will receive a
          1099 tax form (if applicable) reflecting total value of prizes won in
          the Contest.
        </p>
      </li>
      <li>
        <p>
          <strong>Limitations of Liability:</strong> Except where prohibited by
          applicable law, Sponsor and its agencies (including Administrator) are
          not responsible for failed, partial or garbled computer transmissions,
          or for technical failures to the extent that they occur for reasons
          beyond the reasonable control of Sponsor and its agencies, including
          but not limited to human, typographical, printing or electronic
          malfunction, any damage caused by or relating to any downloadable
          content or damage of any network, hardware or software (the preceding
          expressly includes but is not limited to online gaming platforms).
          Subject only to the Régie des alcools des courses et des jeux (the
          “Régie”) with respect to residents of Quebec, if for any reason the
          Contest (in whole or in part) is not capable of running as planned,
          including but not limited to, infection by computer virus, bugs,
          tampering, unauthorized intervention, fraud or technical failures
          beyond Sponsor’s control that corrupt or affect the administration,
          security, fairness, integrity or proper conduct of the Contest,
          Sponsor reserves the right, in its sole discretion, to cancel,
          terminate, modify or suspend the Contest (in whole or in part) and/or
          disqualify any individual who Sponsor reasonably believes tampered
          with (or attempts to tamper with) the entry process/operation of the
          Contest (or any part thereof). In such event, Sponsor shall conduct
          the Contest and make prizes available for the affected part(s) of the
          Contest in a manner which is fair, appropriate and consistent with the
          spirit of these Official Rules, as determined by Sponsor in its sole
          discretion. Notice of cancellation/termination/modification/suspension
          of Contest will be posted on the Website. No responsibility is assumed
          for any error, omission, interruption, deletion, defect, delay in
          operation or transmission, communications line failure, theft or
          destruction or unauthorized access to, or alteration of, entries/game
          plays; or any problems or technical malfunctions of any telephone
          network or lines, computer online systems, servers, or providers,
          computer equipment, software, failure of any e-mail, or entry/game
          play to be received on account of technical problems or traffic
          congestion on the Internet or at any website, or any combination
          thereof, including any injury or damage to entrant’s or any other
          person’s computer (or data/information stored thereon) related to or
          resulting from participation in or downloading any materials from this
          Contest. The preceding expressly includes but is not limited to any
          entry/game play being corrupted, lost, damaged, garbled or not
          properly recorded (or not recorded at all) due to filtering by or
          other operation of the functionality of an online gaming platform.
        </p>
        <p>
          <strong>
            <u>Germany entrants only:</u>
          </strong>{' '}
          Sponsor shall be fully liable for intent and gross negligence as well
          as for damages caused by injury to life, body or health caused by
          Sponsor. In an event of slight negligence, Sponsor shall be liable
          only for breaches of a material contractual obligation (“cardinal
          duty”). A cardinal duty in the meaning of this provision is an
          obligation whose fulfillment makes the implementation of this contract
          possible in the first place and on the fulfillment of which the
          contractual partner may therefore generally rely. Liability for slight
          negligence shall be limited to the typical and foreseeable damages.
        </p>
      </li>
      <li>
        <p>
          <strong>QUEBEC RESIDENTS LITIGATION/DISPUTE RESOLUTION.</strong> Any
          litigation respecting the conduct or organization of a publicity
          contest may be submitted to the Régie for a ruling. Any litigation
          respecting the awarding of a prize may be submitted to the Régie only
          for the purpose of helping the parties reach a settlement.
        </p>
      </li>
      <li>
        <p>
          <strong>Additional Conditions of Participation:</strong> By
          participating, entrants agree to be bound by and abide by these
          Official Rules and the decisions of Sponsor and Administrator, which
          shall be final and binding in all respects. By participating in this
          Contest, entrants agree to the fullest extent permitted by law, to
          release and hold harmless Sponsor, Administrator, Husqvarna,
          Bungie.net, Activision, and each of their respective parents,
          subsidiaries, affiliated and successor companies, advertising and
          promotion agencies and prize suppliers, and each of their respective
          officers, directors, agents, representatives and employees, as well as
          each of their respective successors, representatives and assigns, and
          (collectively, the <strong>“Released Parties”</strong>) from any and
          all actions, claims, injury, loss or damage to person(s) or property
          arising in any manner, directly or indirectly, from participation in
          this Contest and/or acceptance, possession, receipt or use/misuse of
          any prize(s). By participating, entrant agrees that in the event of a
          win, entrant will take part in reasonable publicity connected with the
          Contest (including by attending and participating in the Live Finals
          Event if applicable) if requested by Sponsor and entrant also
          authorizes the Sponsor and its designees to use the entrant’s name,
          voice, likeness, photograph, prize information, biographical data and
          city, and state/province of residence in programming, advertising,
          trade and/or promotional material or on a winners’ list (if
          applicable) without compensation or notice, for the purposes of
          administering and carrying out the Contest, for conducting the Live
          Finals Event and for post-Contest publicity relating to the Contest
          and the Live Finals Event. Sponsor is not obligated to use any of the
          above mentioned information or materials, but may do so and may edit
          such information or materials, at Sponsor’s sole discretion, without
          obligation or compensation.
        </p>
        <p>
          Entrants further agree not to knowingly damage or cause interruption
          of the Contest and/or prevent others from participating in the
          Contest. CAUTION: ANY ATTEMPT TO DAMAGE ANY ONLINE SERVICE OR WEBSITE
          OR OTHERWISE UNDERMINE THE LEGITIMATE OPERATION OF THE PROMOTION
          VIOLATES CRIMINAL OR CIVIL LAWS. IF SUCH AN ATTEMPT IS MADE OR AN
          INDIVIDUAL OTHERWISE ATTEMPTS TO DEFRAUD SPONSOR, SPONSOR MAY
          DISQUALIFY ANY PARTICIPANT MAKING SUCH ATTEMPT AND SEEK DAMAGES TO THE
          FULLEST EXTENT OF THE LAW.
        </p>
        <p>
          <strong>
            NOTHING IN THESE OFFICIAL RULES LIMITS, EXCLUDES OR MODIFIES OR
            PURPORTS TO LIMIT, EXCLUDE OR MODIFY ANY STATUTORY CONSUMER
            GUARANTEES OR ANY IMPLIED CONDITION OR WARRANTY THE EXCLUSION OF
            WHICH FROM THESE TERMS AND CONDITIONS WOULD CONTRAVENE ANY STATUTE
            OR CAUSE ANY PART OF THESE TERMS AND CONDITIONS TO BE VOID (“NON-
            EXCLUDABLE GUARANTEES”). EXCEPT FOR ANY NON-EXCLUDABLE GUARANTEES,
            AND THE SPONSOR’S OWN PRODUCT/SERVICE THAT MAY BE COVERED BY A
            WARRANTY (WITH SUCH WARRANTY BEING EXPRESSLY LIMITED TO ITS TERMS
            SUBJECT TO ANY NON-EXCLUDABLE GUARANTEE), SPONSOR MAKES NO WARRANTY,
            REPRESENTATION, OR GUARANTEE, EXPRESS OR IMPLIED, IN FACT OR IN LAW,
            RELATIVE TO THE USE OF ANY PRIZE (OR ANY COMPONENT OF ANY PRIZE),
            INCLUDING, WITHOUT LIMITATION, ITS QUALITY, MERCHANTABILITY OR
            FITNESS FOR A PARTICULAR PURPOSE.
          </strong>
        </p>
        <p>
          <strong>
            NOTHING IN THESE OFFICIAL RULES LIMITS OR EXCLUDES LIABILITY FOR
            DEATH OR PERSONAL INJURY CAUSED BY NEGLIGENCE, FOR FRAUD OR FOR ANY
            OTHER LIABILITY WHICH CANNOT BE LIMITED OR EXCLUDED AS A MATTER OF
            APPLICABLE LAW. ANY PROVISION IN THESE OFFICIAL RULES THAT PURPORTS
            TO LIMIT OR EXCLUDE LIABILITY IS SUBJECT ALWAYS TO THIS OVERRIDIDING
            PROVISION, WHICH SHALL TAKE PRECEDENCE.
          </strong>
        </p>
      </li>
      <li>
        <p>
          <strong>(This section does not apply to German entrants.)</strong>{' '}
          Entrant agrees that, to the fullest extent permitted by applicable
          law: (i) any and all disputes, claims and causes of action arising out
          of or connected with the Contest, other than those concerning the
          administration of the Contest or the determination of winner or for
          any disputes arising from the loss or injury from the participation in
          a prize, shall be resolved individually, without resort to any form of
          class action; (ii) any disputes arising out of these Official Rules
          (except for any disputes arising from the loss or injury from the use
          of prizes) shall be submitted to final, binding arbitration conducted
          in Nevada, under the Arbitration Rules and Procedures of the Judicial
          Arbitration and Mediation Services Inc. before a single, neutral
          arbitrator who is a former or retired Nevada state or federal court
          judge with experience in entertainment matters who shall follow Nevada
          law and the Federal Rules of Evidence and have no authority to award
          punitive damages. Either party may enforce a final arbitration award
          in any court of competent jurisdiction in Nevada, including an award
          of costs, fees and expenses incurred in enforcing the award.
          Notwithstanding the foregoing, Sponsor shall be entitled to seek
          injunctive relief (unless otherwise precluded by any other provision
          of these Official Rules) in the state and federal courts of Nevada.
          Any dispute or portion thereof, or any claim for a particular form of
          relief (not otherwise precluded by any other provision of these
          Official Rules), that may not be arbitrated pursuant to applicable
          state or federal law may be heard only in a court of competent
          jurisdiction in Nevada; (iii) any and all claims, judgments and awards
          shall be limited to actual out-of-pocket costs incurred, including
          costs associated with entering the Contest, but in no event attorneys’
          fees; and (iv) under no circumstances will entrant be permitted to
          obtain awards for, and entrant hereby waives all rights to claim
          punitive, incidental and consequential damages and any other damages,
          other than for actual out-of-pocket expenses, and any and all rights
          to have damages multiplied or otherwise increased.{' '}
          <strong>
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY
            NOT APPLY TO YOU.
          </strong>{' '}
          All issues and questions concerning the construction, validity,
          interpretation and enforceability of these Official Rules, or the
          rights and obligations of the entrant and Sponsor in connection with
          the Contest, shall be governed by, and construed in accordance with,
          the laws of the State of Nevada, without giving effect to any choice
          of law or conflict of law rules (whether of the State of Nevada, or
          any other jurisdiction), which would cause the application of the laws
          of any jurisdiction other than the State of Nevada.
        </p>
        <p>
          <strong>This section only applicable to German entrants:</strong> Any
          disputes arising out of these Official Rules (except for any disputes
          arising from the loss or injury from the use of Prizes) shall be
          submitted to German law.
        </p>
      </li>
      <li>
        <p>
          <strong>Winners’ List:</strong> By entering this Content, you
          acknowledge that if you are a prize winner your name (and if you are a
          UK resident, your county of residence) will, to the extent required by
          applicable law, be disclosed to persons enquiring. For the names of
          the winners, mail a self-addressed, stamped envelope to: Rockstar
          Destiny 2 Forsaken Game Challenge Winners, c/o Brandmovers Inc., 590
          Means St., Atlanta, GA, for receipt no later than February 21, 2019.
          No information will be disclosed to enquirers until the end of the
          Contest Period.
        </p>
      </li>
      <li>
        <p>
          <strong>Personal information:</strong> For the purposes of applicable
          data protection laws, Sponsor is the data controller in respect of any
          personal data collected in connection with the Contest. Sponsor will
          only use and share any personal information collected in connection
          with the Contest for the administration of the Contest and as
          otherwise set out in these Official Rules and Sponsor’s Privacy Notice
          at{' '}
          <a
            href="http://rockstarenergy.com/company/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://rockstarenergy.com/company/privacy
          </a>
          , unless Sponsor has obtained the relevant entrant’s consent or has
          another lawful basis to undertake further processing. It is necessary
          for Sponsor to use entrants’ personal information to perform its
          obligations to entrants in relation to the administration of the
          Contest, including the award and delivery of prizes. For any
          processing purposes where contractual necessity does not apply, it is
          in Sponsor’s legitimate interest to use entrants’ personal information
          in the ways described in these Official Rules in order to ensure the
          proper administration of the Contest. Sponsor will only share
          entrants’ personal information for the above purposes with its group
          companies and suppliers (including Administrator) who are assisting
          with the administration of the Contest. Sponsor and its group
          companies and other suppliers are located in the United States of
          America and therefore by entering the Contest entrants acknowledge
          that their personal information will be transferred and processed by
          Sponsor, its group companies and suppliers (including Administrator)
          outside the European Economic Area. Sponsor will take reasonable steps
          to ensure the security of all personal information. Entrants have
          certain rights in relation to the processing of their personal
          information, including rights of access, rectification and erasure, a
          right to restrict processing, a right to data portability and the
          right to object to certain types of processing. For more information
          about Sponsor’s processing activities and how to exercise entrants’
          rights, please see Sponsor’s Privacy Notice at{' '}
          <a
            href="http://rockstarenergy.com/company/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://rockstarenergy.com/company/privacy
          </a>
          . If there are concerns about any aspect of Sponsor’s privacy
          practices, please contact Sponsor using the contact details provided
          in Sponsor’s Privacy Notice. Entrants can also report any issues or
          concerns to a national supervisory authority. A list of contact
          details for all EU supervisory authorities can be found at:{' '}
          <a
            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
          .
        </p>
      </li>
      <li>
        <p>
          <strong>Miscellaneous:</strong> If any provision or part-provision of
          these Official Rules is or becomes invalid, illegal or unenforceable,
          it shall be deemed deleted, but that shall not affect the validity and
          enforceability of the rest of these Official Rules. If there is a
          conflict between the English version of these Official Rules and any
          translated version, the English version shall take precedence to the
          extent necessary to resolve the conflict.
        </p>
      </li>
    </ol>
    <p>
      <strong>ROCKSTAR INC.</strong>
    </p>
    <p>
      <strong>Rockstar Riots Destiny 2: Forsaken Game Competition</strong>
    </p>
    <p>
      <strong>Abbreviated Rules</strong>
    </p>
    <p>
      <strong>Rockstar Riots Destiny 2: Forsaken Game Competition</strong>{' '}
      (“Contest”) starts 12:00:00 am United States Eastern Time (“ET”) on
      October 7, 2018 and ends 11:59:59 pm ET on December 1, 2018. Open only to
      legal residents of the 50 U.S and D.C. (
      <strong>
        <u>excluding</u>
      </strong>{' '}
      residents of AZ, CO, CT, ND, NE and MD), Canada, the United Kingdom and
      Germany, who are 18 or older, or the age of majority in their
      state/province of residence, at the time of entry and who{' '}
      <strong>
        <u>prior to</u> October 5, 2018
      </strong>{' '}
      have Internet access, a valid e-mail address, and the Destiny 2 game for
      PS4, Xbox One™ or PC, a valid subscription to either Playstation® Plus, or
      Xbox Live Gold (no subs req’d for PC users), and an online account with
      Bungie.net. Entrants may only enter using one (1) Bungie.net account. 4x
      Grand Prize require air/ground travel to Bellevue, WA, USA on December
      16-19, 2018. Entrants must have a valid passport, visa and/or other
      required travel documents to be eligible for travel. Prizes
      non-transferable and non-exchangeable. No cash alternative available. Void
      in AZ, CO, CT, ND, NE and MD, and where restricted or prohibited. Terms
      and exclusions apply. Visit{' '}
      <a href="https://destiny2.rockstarenergy.com">
        Destiny2.Rockstarenergy.com
      </a>{' '}
      for official rules, how to enter and access the Leaderboard.{' '}
      <strong>Sponsor:</strong> Rockstar, Inc., 101 Convention Center Dr., Suite
      777, Las Vegas, NV 89109.
    </p>
  </div>
);

export default RulesCompetition;

import React, { Fragment } from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import HeaderStyled from './Header.style';

import Button from '../Button/Button';
import ModalController from '../ModalController/ModalController';
import ModalProgramOver from '../ModalProgramOver/ModalProgramOver';
import ModalRules from '../ModalRules/ModalRules';
import ModalPrivacy from '../ModalPrivacy/ModalPrivacy';
import DropdownController from '../DropdownController/DropdownController';
import Dropdown from '../Dropdown/Dropdown';

const Header = ({ hideLinks, pathname }) => {
  const pathName = pathname.replace(/\//g, '');

  return (
    <HeaderStyled>
      <div className="Header__inner">
        <div className="Header__logos">
          <Link className={cx('Header__logo', 'rs')} to="/">
            &nbsp;
          </Link>
          <Link className={cx('Header__logo', 'd2')} to="/">
            &nbsp;
          </Link>
        </div>

        {!hideLinks && (
          <nav className="Header__nav">
            <div className="Header__links">
              <Link
                className={cx('Header__link', 'smHidden', {
                  active: pathName === 'competition',
                })}
                to="/competition"
              >
                COMPETITION
              </Link>

              <ModalController>
                {(isOpen, handleToggle) => (
                  <div className="Header__links">
                    <button
                      className="Header__link smHidden"
                      type="button"
                      onClick={handleToggle}
                    >
                      MY DASHBOARD
                    </button>

                    <button
                      className="Header__link smHidden"
                      type="button"
                      onClick={handleToggle}
                    >
                      Log In
                    </button>

                    <Button size="sm" onClick={handleToggle}>
                      ENTER CODE
                    </Button>

                    {isOpen && <ModalProgramOver onClose={handleToggle} />}
                  </div>
                )}
              </ModalController>
            </div>

            <div className="Header__menu">
              <DropdownController>
                {(isOpen, handleToggle) => (
                  <Fragment>
                    <button
                      className="Header__menuBtn"
                      type="button"
                      onClick={handleToggle}
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </button>

                    {isOpen && (
                      <Dropdown className="Header__dropdown">
                        <ModalController>
                          {(isOpen, handleToggle) => (
                            <Fragment>
                              <button
                                className="Header__dropdownLink"
                                type="button"
                                onClick={handleToggle}
                              >
                                LOG IN
                              </button>

                              <button
                                className="Header__dropdownLink"
                                type="button"
                                onClick={handleToggle}
                              >
                                SIGN UP
                              </button>

                              {isOpen && (
                                <ModalProgramOver onClose={handleToggle} />
                              )}
                            </Fragment>
                          )}
                        </ModalController>

                        <ModalController>
                          {(isOpen, handleToggle) => (
                            <Fragment>
                              <button
                                className="Header__dropdownLink"
                                type="button"
                                onClick={handleToggle}
                              >
                                OFFICIAL RULES
                              </button>

                              {isOpen && <ModalRules onClose={handleToggle} />}
                            </Fragment>
                          )}
                        </ModalController>

                        <ModalController>
                          {(isOpen, handleToggle) => (
                            <Fragment>
                              <button
                                className="Header__dropdownLink"
                                type="button"
                                onClick={handleToggle}
                              >
                                PRIVACY POLICY
                              </button>

                              {isOpen && (
                                <ModalPrivacy onClose={handleToggle} />
                              )}
                            </Fragment>
                          )}
                        </ModalController>
                      </Dropdown>
                    )}
                  </Fragment>
                )}
              </DropdownController>
            </div>
          </nav>
        )}
      </div>
    </HeaderStyled>
  );
};

export default Header;

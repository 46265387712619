import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cx from 'classnames';
import { Link } from 'gatsby';

import * as defaultTheme from '../variables';

const handleKeyPress = evt => {
  if (evt.charCode === 32 || evt.charCode === 13) {
    evt.preventDefault();
    evt.target.click();
  }
};

const Button = styled(
  ({ className, theme, size, variant, block, to, children, ...rest }) =>
    to ? (
      <Link className={cx('Button', className)} to={to} {...rest}>
        {children}
      </Link>
    ) : (
      <button className={cx('Button', className)} {...rest}>
        {children}
      </button>
    ),
)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${p => (p.size === 'sm' ? 32 : 50)}px;
  margin: 0;
  padding: 0
    ${p =>
      (p.size === 'sm' && 9) ||
      (p.size === 'md' && 32) ||
      (p.size === 'lg' && 64)}px;
  color: ${p => (p.variant === 'info' ? p.theme.white : p.theme.black)};
  background-color: ${p => p.theme[p.variant]};
  background-image: none;
  font-size: ${p => (p.size === 'sm' ? '12px' : 'inherit')};
  font-weight: 700;
  vertical-align: middle;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: ${p => p.size === 'sm' && '32px'};
  white-space: nowrap;
  border: 1px solid
    ${p => (p.variant === 'info' ? p.theme.white : 'transparent')};
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;

  @media (${p => p.theme.smMin}) {
    width: ${p => (p.block ? '100%' : 'auto')};
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &::-moz-focus-inner {
    border: none;
  }
`;

Button.displayName = 'Button';

Button.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['primary', 'secondary', 'info']),
  block: PropTypes.bool,
  to: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.oneOf(['button', 'submit']),
};

Button.defaultProps = {
  theme: defaultTheme,
  size: 'lg',
  variant: 'primary',
  block: false,
  to: null,
  children: null,
  onKeyPress: handleKeyPress,
  type: 'button',
};

export default Button;

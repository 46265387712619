import React from 'react';

const RulesSweeps = () => (
  <div>
    <p>
      <strong>Rockstar Destiny 2 Sweepstakes Official Rules</strong>
    </p>
    <p>
      <strong>
        NO PURCHASE OR PAYMENT NECESSARY. A PURCHASE WILL NOT IMPROVE YOUR
        CHANCE OF WINNING. VOID WHERE PROHIBITED.
      </strong>
    </p>
    <p>
      <strong>
        BY ENTERING THE SWEEPSTAKES, ENTRANT AGREES TO THESE OFFICIAL RULES.
      </strong>
    </p>

    <ol>
      <li>
        <p>
          <strong>Eligibility:</strong> The Rockstar Destiny 2 Sweepstakes (the
          “Sweepstakes”) is open only to legal residents of the 50 United States
          and District of Columbia and Canada, who are the age of majority or
          older in state/province of residence. Void where prohibited by law. No
          purchase necessary to enter the Sweepstakes. Employees of Rockstar,
          Inc., 101 Convention Center Dr., Suite 777, Las Vegas, NV 89109 (the
          “Sponsor”), Brandmovers, Inc., 590 Means Street, Suite 250, Atlanta,
          GA 30318 (“Administrator”), or any of their respective affiliates,
          subsidiaries, advertising agencies, or any other company or individual
          involved with the design, production execution or distribution of the
          Sweepstakes, including, without limitation, Bungie, Inc. and
          Activision Publishing, Inc. (collectively with Sponsor and
          Administrator, the “Sweepstakes Entities”) and their immediate family
          (spouse, parents and step-parents, siblings and step- siblings, and
          children and step-children) and household members of each such
          employee are not eligible to enter or win.  The Sweepstakes is subject
          to all applicable federal, state, and local laws and regulations.
          Participation constitutes entrant’s full and unconditional agreement
          to these Official Rules and Sponsor’s decisions, which are final and
          binding in all matters related to the Sweepstakes.  Winning a prize is
          contingent upon fulfilling all requirements set forth herein.
        </p>
      </li>
      <li>
        <p>
          <strong>Sweepstakes Entry Periods:</strong> The Sweepstakes begins on
          September 3, 2018 at 12:00:01 PM Eastern Time (“ET”) and ends on
          October 30, 2018 at 11:59:59 AM ET (the “Sweepstakes Period”). The
          Sweepstakes Period will be broken out into three (3) separate entry
          pools as follows:
        </p>
        <ol>
          <li>
            fifty-seven (57) daily entry periods, each running from noon to noon
            (beginning at 12:00:01 PM ET and ending at 11:59:59 AM ET the
            following day), throughout the Sweepstakes Period (each, a “Daily
            Entry Pool”) as specifically set forth in the Entry and Drawing
            Chart below;
          </li>
          <li>
            eight (8) weekly entry periods, each beginning at 12:00:01 AM ET on
            Monday and ending at 11:59:59 PM ET the following Sunday of each
            week during the Sweepstakes Period (each, a “Weekly Entry Pool”) as
            specifically set forth in the Entry and Drawing Chart below; and
          </li>
          <li>
            and a grand prize entry period which will coincide with the
            Sweepstakes Period (the “Grand Prize Entry Pool”).
          </li>
        </ol>
        <p>
          The Website (defined below) clock is the official timekeeper for this
          Sweepstakes.
        </p>
      </li>
      <li>
        <p>
          <strong>How to Enter:</strong> During the Sweepstakes Period, look for
          a code under the tab of specially-marked cans of Rockstar Energy Drink
          (“Code”). Once you have your Code, to register to enter the
          Sweepstakes, visit{' '}
          <a href="https://destiny2.rockstarenergy.com">
            Destiny2.RockstarEnergy.com
          </a>{' '}
          (“Website”) find and complete the registration form by providing your
          name, email address, mobile number, and creating a password to log in.
          Once you’ve registered, input your Code where indicated on the Website
          to automatically receive one (1) entry into that day’s Daily Entry
          Pool, one (1) entry into that week’s Weekly Entry Pool and one (1)
          entry into the Grand Prize Entry Pool, simultaneously. In the event
          that ten (10) invalid Codes are entered within fifteen (15) minutes,
          the user’s account will be frozen for one (1) hour. In the event of a
          dispute as to the identity of entrant, entry will be deemed made by
          the authorized account holder of the e-mail address used to enter the
          Sweepstakes, as determined by Sponsor, in its sole discretion. Entry
          information must be manually key-stroked; use of automated means of
          entry is prohibited and entries made via such improper means will be
          disqualified. Responsibility for receipt of entry rests solely with
          the entrant.
        </p>
        <p>
          To enter without purchase and without obtaining a code (“AMOE”), send
          a 3x5 paper/card with your complete name, address, email address,
          telephone number, birth date, and the Daily Entry Date you are
          entering, and send to the following mailing address: P O Box 93245
          Atlanta, GA  30377, ATTN: Brandmovers, Inc., to be received by the
          “Daily Received by date” in the Entry and Drawing Chart below for a
          specific Entry Pool, or by November 5, 2018 to be entered into the
          final Daily Entry Pool, final Weekly Entry Pool and Grand Prize Entry
          Pool simultaneously. Limit one (1) AMOE request per outer envelope.
          All entries become the property of Sponsor and will not be
          acknowledged or returned. Proof of submission of completed entry form
          does not constitute proof of receipt or proof of entry in Sweepstakes.
        </p>
      </li>
      <li>
        <p>
          <strong>RANDOM DRAWINGS:</strong> For each Daily Entry Pool, three (3)
          Daily Winners will be selected via a random drawing on the dates set
          forth in the Daily Entry and Drawing Chart below; for each Weekly
          Entry Pool, three (3) Weekly Winners will be selected via a random
          drawing on the dates set forth in the Weekly Entry and Drawing Chart
          below; and for the Grand Prize Entry Pool, one (1) Grand Prize Winner
          will be selected via a random drawing that will occur on November 8,
          2018. Each random drawing will be conducted by Administrator from
          among all eligible entries received in the applicable Entry Pool on
          the dates set forth in these official rules.{' '}
          <strong>Limit one (1) prize per person.</strong> All non-winning
          entries from any Entry Pool <strong>WILL</strong> roll over into
          subsequent Entry Pools. The potential Winners will be notified by
          email on or about the day of the applicable random drawing, and each
          will have <strong>seventy-two (72)</strong> hours from the time a
          Winner notification is sent by Administrator to respond to the
          notification. In the event a potential Winner fails to respond to a
          Winner notification with seventy-two (72) hours, he/she will forfeit
          his/her right to the Prize and the Prize may, at Sponsor’s sole
          discretion, be awarded to an alternate Winner, time permitting.
        </p>
        <img src="/assets/images/Destiny/UsCaRules.png" alt="" />
        <p>
          Upon receiving the Winner notification response, Administrator may
          send each potential Winner an Affidavit of Eligibility and Publicity
          (where legal) and Liability Release (if a United States resident) OR a
          Declaration of Eligibility and Liability Release (if a Canadian
          resident), which will need to be completed, signed and returned to
          Administrator within seven (7) days of receipt of the Release. If a
          resident of Canada, the potential winner must also correctly answer a
          time-based mathematical skill-testing question without assistance of
          any kind prior to and as a condition of being declared a winner. If
          any Prize notification or attempted notification is returned as
          undeliverable, that Prize may be forfeited and awarded to an alternate
          Winner, time permitting. Administrator and Sponsor are not responsible
          for lost, late, damaged, delayed, or illegible paperwork. By signing
          the Release, Winners acknowledge compliance with these Official Rules.
          Odds of winning a Prize depend upon the number of eligible entries
          received.
        </p>
      </li>
      <li>
        <p>
          <strong>CONDITIONS FOR AWARDING PRIZE:</strong> Winners must meet all
          eligibility requirements. In the event a potential Winner is deemed
          ineligible or is not available to claim a Prize during the specified
          dates, then an alternate Winner may be selected at random from
          eligible entries received, time permitting.
        </p>
      </li>
      <li>
        <p>
          <strong>
            Prizes and Approximate Retail Values (“ARVS”): One (1) Grand Prize
            Winner -
          </strong>{' '}
          A Custom
          <sup>**</sup> Polaris Slingshot®. ARV: $25,799.00 USD.{' '}
          <strong>Twenty-four (24) Weekly Winners</strong> (3 Weekly Prizes
          awarded per Weekly Entry Pool)– A PS4™ Pro System and Destiny 2:
          Forsaken – Digital Deluxe Edition Game for the PS4™ system. ARV:
          $480.00 USD each.{' '}
          <strong>One hundred seventy-one (171) Daily Prizes</strong> (3 Daily
          Prizes awarded per Daily Entry Pool) – A Destiny 2: Forsaken Game +
          Expansion Pass Bundle for PS4™ system. ARV: $70.00 each. Total ARV of
          all prizes is: $53,490.00 USD. . Prize will be awarded as is. Polaris
          Industries Inc., 2100 Highway 55 Medina, MN 55340 (POLARIS) gives a
          two year limited warranty on all components of your POLARIS vehicle
          against defects in material or workmanship. This warranty covers parts
          and labor charges for repair or replacement of defective parts and
          begins on the date of the purchase by the original retail purchaser.
          This warranty is transferrable to another owner during the warranty
          period through an authorized POLARIS dealer, but any such transfer
          will not extend the original term of the warranty. The duration of
          this warranty may vary by international region based upon local laws
          and regulations. Prizes consist of only those items specifically
          listed as part of the prize. No cash or other substitution allowed by
          any Winner, but Sponsor may substitute a Prize, or any portion
          thereof, for a prize of equal or greater value at Sponsor’s sole
          discretion for any reason. The Prizes are non-assignable and
          non-transferable. Winners are responsible for paying all applicable
          income taxes associated with accepting their Prize. A 1099 form may be
          submitted to all appropriate taxing authorities, if applicable.
        </p>
        <p>
          <sup>**</sup>
          The Polaris Slingshot® will be exclusively customized by Sponsor with
          no substitutions or credit toward any other Slingshot®.
        </p>
        <p>
          <u>GRAND PRIZE TERMS:</u> The exact year of the Grand Prize vehicle is
          at Sponsor’s sole discretion and depends on the time the winner is
          confirmed, and vehicle is delivered to the dealer. The vehicle will be
          delivered as determined solely by Sponsor. The difference in value as
          stated herein and value at time of prize notification, if any, will
          not be awarded in cash or otherwise. Any upgrades and options are at
          the sole expense of the Grand Prize Winner. Package, factory options,
          and other specifics, including color, to be determined by Sponsor, and
          are subject to availability. Grand Prize Winner must be the age of
          majority in his/her state/province and must present a current valid
          U.S. vehicle operator endorsement and proof of insurance prior to
          taking possession of the vehicle, failure to show same may result in
          Grand Prize forfeiture and selection of an alternate potential Grand
          Prize Winner. Grand Prize Winner must also show proof of possession of
          any required safety equipment and knowledge of how to safely operate
          that Polaris product, and any other requirement of a Polaris dealer.
          Grand Prize Winner is responsible for all associated dealership fees
          including all title, license, insurance, registration, dealer
          preparation, applicable taxes or fees, destination and handling
          charges, and other fees associated with vehicle, including any travel
          and transportation costs associated with collecting the vehicle from
          the local dealership where it is delivered. All other costs not
          specifically stated herein as being awarded are the responsibility of
          Grand Prize Winner. Grand Prize Winner must take delivery of vehicle
          from the designated dealer within thirty (30) days after the date
          Sponsor informs Grand Prize winner that the Grand Prize is available
          to be picked up at the specified designated dealer, or the vehicle may
          (in Sponsors’ sole discretion) be automatically forfeited by the Grand
          Prize Winner. no person associated with the manufacture, distribution,
          development and execution of this Sweepstakes and its prize(s), or any
          of their respective affiliates, parent companies, subsidiaries,
          directors, officers, employees, and agents makes any warranties,
          representations or guarantees, express or implied, in fact or in law,
          relative to the use or enjoyment of the vehicle, including, without
          limitation, its quality, mechanical condition, merchantability or
          fitness for a particular purpose.
        </p>
      </li>
      <li>
        <p>
          <strong>PRIVACY:</strong> Promotion Entities and their authorized
          agents will collect, use, and disclose the personal information you
          provide when you enter the Promotion solely for the purposes of
          administering the Promotion and fulfilling prizes, and by entering
          this Promotion you consent to the collection, use, disclosure and
          management of your personal information for said purposes. All
          personal information that Entrants submit will be subject to Sponsor’s
          privacy policy. In the event of any discrepancy between Sponsor’s
          privacy policy and these Official Rules, the privacy policy will
          govern and control. For a copy of Sponsor’s complete online privacy
          policy please visit{' '}
          <a href="http://rockstarenergy.com/company/privacy">
            http://rockstarenergy.com/company/privacy
          </a>
          . Information collected by Sponsor in connection with this promotion
          will be maintained on servers located in the United States.
        </p>
      </li>
      <li>
        <p>
          <strong>QUEBEC RESIDENTS LITIGATION/DISPUTE RESOLUTION.</strong> Any
          litigation respecting the conduct or organization of a publicity
          contest may be submitted to the Régie for a ruling. Any litigation
          respecting the awarding of a prize may be submitted to the Régie only
          for the purpose of helping the parties reach a settlement.
        </p>
      </li>
      <li>
        <p>
          <strong>Entry Conditions and Release:</strong> By entering, each
          entrant agrees to: (a) comply with and be bound by these Official
          Rules and the decisions of the Sponsor which are binding and final in
          all matters relating to this Sweepstakes; (b) release and hold
          harmless the Sweepstakes Entities from and against any and all claims,
          expenses, and liability, including but not limited to negligence and
          damages of any kind to persons and property, including but not limited
          to invasion of privacy (under appropriation, intrusion, public
          disclosure of private facts, false light in the public eye or other
          legal theory), defamation, slander, libel, violation of right of
          publicity, infringement of trademark, copyright or other intellectual
          property rights, property damage, or death or personal injury arising
          out of or relating to a participant’s entry, creation of an entry or
          submission of an entry, participation in the Sweepstakes, the
          Submission, acceptance or use or misuse of prize  and/or the
          broadcast, exploitation or use of entry or the Submission; and (c)
          indemnify, defend and hold harmless the Sweepstakes Entities from and
          against any and all claims, expenses, and liabilities (including
          reasonable attorney’s fees) arising out of or relating to an entrant’s
          participation in the Sweepstakes and/or entrant’s acceptance, use or
          misuse of prize or any portion thereof.
        </p>
      </li>
      <li>
        <p>
          <strong>General Conditions:</strong> Subject only to the Régie des
          alcools des courses et des jeux (the “Régie”) with respect to
          residents of Quebec, Sponsor reserves the right to cancel, suspend
          and/or modify the Sweepstakes, or any part of it, for any reason
          whatsoever, including, without limitation, fire, flood, natural or
          man-made epidemic of health of other means, earthquake, explosion,
          labor dispute or strike, act of God or public enemy, satellite or
          equipment failure, riot or civil disturbance, terrorist threat or
          activity, war (declared or undeclared) or any federal state or local
          government law, order, or regulation, public health crisis, order of
          any court or jurisdiction or if any fraud, technical failures or any
          other factor beyond Sponsor’s reasonable control impairs the integrity
          or proper functioning of the Sweepstakes, as determined by Sponsor in
          its sole discretion. If the Sweepstakes is terminated before the
          designated end date, Sponsor will (if possible) select the winner by
          random drawing from all eligible, non-suspect entries received as of
          the date of the event giving rise to the termination.  Inclusion in
          such random drawing shall be each entrant’s sole and exclusive remedy
          under such circumstances. Sponsor reserves the right in its sole
          discretion to disqualify any individual it finds to be tampering with
          the entry process or the operation of the Sweepstakes or to be acting
          in violation of these Official Rules or any other promotion or in an
          unsportsmanlike or disruptive manner. Any attempt by any person to
          deliberately undermine the legitimate operation of the Sweepstakes may
          be a violation of criminal and civil law, and, should such an attempt
          be made, Sponsor reserves the right to seek damages from any such
          person to the fullest extent permitted by law. Only the type and
          quantity of prizes described in these Official Rules will be awarded.
          The invalidity or unenforceability of any provision of these rules
          shall not affect the validity or enforceability of any other
          provision. In the event that any provision is determined to be invalid
          or otherwise unenforceable or illegal, these rules shall otherwise
          remain in effect and shall be construed in accordance with their terms
          as if the invalid or illegal provision were not contained herein.
          Sponsor’s failure to enforce any term of these Official Rules shall
          not constitute a waiver of that provision.
        </p>
      </li>
      <li>
        <p>
          <strong>Limitations of Liability:</strong> The Sweepstakes Entities as
          set forth above are not responsible for: (1) any incorrect or
          inaccurate information, whether caused by entrants, printing errors or
          by any of the equipment or programming associated with or utilized in
          the Sweepstakes; (2) technical failures of any kind, including, but
          not limited to malfunctions, interruptions, or disconnections in phone
          lines or network hardware or software; (3) unauthorized human
          intervention in any part of the entry process or the Sweepstakes; (4)
          technical or human error which may occur in the administration of the
          Sweepstakes or the processing of entries; or (5) any injury or damage
          to persons or property which may be caused, directly or indirectly, in
          whole or in part, from entrant’s participation in the Sweepstakes or
          receipt or use or misuse of any Prize. If for any reason an entry is
          confirmed to have been erroneously deleted, lost, or otherwise
          destroyed or corrupted, entrant’s sole remedy is another entry in the
          Sweepstakes, provided that if it is not possible to award another
          entry due to discontinuance of the Sweepstakes, or any part of it, for
          any reason, Sponsor, at its discretion, may elect to hold judging from
          among all eligible entries received up to the date of discontinuance
          for any or all of the Prizes offered herein. Entrant (or entrant’s
          parent/legal guardian, if entrant is a minor in the state of his/her
          residence) further agrees and acknowledges that Sponsor reserves the
          right to forfeit or award any unclaimed or leftover Prize at its sole
          discretion.
        </p>
      </li>
      <li>
        <p>
          <strong>Disputes/Governing Law:</strong> Entrant agrees that: (i) any
          and all disputes, claims and causes of action arising out of or
          connected with this Sweepstakes, other than those concerning the
          administration of the Sweepstakes or the determination of winner or
          for any disputes arising from the loss or injury from the
          participation in a Prize, shall be resolved individually, without
          resort to any form of class action; (ii) any disputes arising out of
          these Official Rules (except for any disputes arising from the loss or
          injury from the use of Prizes) shall be submitted to final, binding
          arbitration conducted in Nevada, under the Arbitration Rules and
          Procedures of the Judicial Arbitration and Mediation Services Inc.
          before a single, neutral arbitrator who is a former or retired Nevada
          state or federal court judge with experience in entertainment matters
          who shall follow Nevada law and the Federal Rules of Evidence and have
          no authority to award punitive damages. Either party may enforce a
          final arbitration award in any court of competent jurisdiction in
          Nevada, including an award of costs, fees and expenses incurred in
          enforcing the award. Notwithstanding the foregoing, Sweepstakes
          Entities shall be entitled to seek injunctive relief (unless otherwise
          precluded by any other provision of these Official Rules) in the state
          and federal courts of Nevada. Any dispute or portion thereof, or any
          claim for a particular form of relief (not otherwise precluded by any
          other provision of these Official Rules), that may not be arbitrated
          pursuant to applicable state or federal law may be heard only in a
          court of competent jurisdiction in Nevada; (iii) any and all claims,
          judgments and awards shall be limited to actual out-of- pocket costs
          incurred, including costs associated with entering this Sweepstakes,
          but in no event attorneys’ fees; and (iv) under no circumstances will
          entrant be permitted to obtain awards for, and entrant hereby waives
          all rights to claim punitive, incidental and consequential damages and
          any other damages, other than for actual out- of-pocket expenses, and
          any and all rights to have damages multiplied or otherwise increased.
          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF
          LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY
          NOT APPLY TO YOU. All issues and questions concerning the
          construction, validity, interpretation and enforceability of these
          Official Rules, or the rights and obligations of the entrant and
          Sponsor in connection with the Sweepstakes, shall be governed by, and
          construed in accordance with, the laws of the State of Nevada, without
          giving effect to any choice of law or conflict of law rules (whether
          of the State of Nevada, or any other jurisdiction), which would cause
          the application of the laws of any jurisdiction other than the State
          of Nevada.
        </p>
      </li>
      <li>
        <p>
          <strong>Sweepstakes Winners:</strong> For the Sweepstakes Winner’s
          name (available after December 9, 2018), send a hand-printed,
          self-addressed, stamped envelope by December 9, 2018 to: Winners List,
          Rockstar Destiny 2 Sweepstakes, Brandmovers, Inc., 590 Means Street,
          Suite 250, Atlanta, GA 30318.
        </p>
      </li>
      <li>
        <p>
          <strong>Official Rules:</strong> For a copy of these Official Rules
          send a hand-printed, self-addressed, stamped envelope by December 9,
          2018 to: Official Rules – Rockstar Destiny 2 Sweepstakes, Brandmovers,
          Inc., 590 Means Street, Suite 250, Atlanta, GA 30318. A copy of the
          Rules may also be obtained by printing this Website.
        </p>
      </li>
      <li>
        <p>
          <strong>Sponsor:</strong> Rockstar, Inc., 101 Convention Center Dr.,
          Suite 777, Las Vegas, NV 89109.
          <br />
          <strong>Administrator:</strong> Brandmovers, Inc., 590 Means Street,
          Suite 250, Atlanta, GA 30318.
        </p>
      </li>
    </ol>
    <p>
      Sony Interactive Entertainment LLC is not a sponsor of this Sweepstakes.
    </p>
    <p>
      Neither Activision Publishing, Inc. nor Bungie, Inc. are sponsors of the
      sweepstakes. © 2018 Bungie, Inc. All rights reserved. Destiny, the Destiny
      logo, Bungie and the Bungie logo are among the trademarks of Bungie, Inc.
      Published and distributed by Activision. “PlayStation”, and the “PS”
      Family logo are registered trademarks, and “PS4” is a trademark of Sony
      Interactive Entertainment Inc. ESRB Rating: T for Teen.
    </p>
    <p>
      Slingshot® is a three-wheeled motorcycle. It is not an automobile. It does
      not have airbags and it does not meet automotive safety standards.
      Three-wheel vehicles may handle differently than other vehicles,
      especially in wet conditions. Always wear a DOT-approved full-face helmet
      and fasten seatbelts. The Driver may need a valid motorcycle endorsement.
      Don’t drink and drive. Actual grand prize Slingshot® Roadster may vary
      from Rockstar Destiny 2 sweepstakes’ picture and description, and may
      include customized advertising wrap.
    </p>
  </div>
);

export default RulesSweeps;

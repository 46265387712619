import styled from 'styled-components';

const ModalProgramOverStyled = styled('div')`
  .ModalProgramOver__title {
    color: #fff;
  }

  .ModalProgramOver__desc {
    margin: 16px 0;
    padding: 20px;
    color: #000;
    background-color: #fff;
    font-size: 14px;
    overflow-x: hidden;

    @media (min-height: 414px) and (min-width: 768px) {
      max-height: 230px;
    }

    @media (min-height: 640px) and (min-width: 768px) {
      max-height: 420px;
    }
  }

  .ModalProgramOver__back {
    float: right;
  }
`;

export default ModalProgramOverStyled;

import styled from 'styled-components';
import { flyIn, flyOut } from '../animations';

const CookieDisclaimerStyled = styled('div')`
  .CookieDisclaimer__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px 20px;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.85);
    animation-duration: 2s;
    animation-fill-mode: forwards;
    z-index: 9000;

    &.isShow {
      animation-name: ${flyIn};
    }

    &.isHide {
      animation-name: ${flyOut};
    }
  }

  .CookieDisclaimer__title {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
  }

  .CookieDisclaimer__desc {
    max-width: 650px;
  }
`;

export default CookieDisclaimerStyled;

import React from 'react';

const RulesAmPm = () => (
  <div>
    <p style={{ textAlign: 'center' }}>
      <strong>
        Rockstar & AMPM Destiny 2 Sweepstakes <br /> Official Rules
      </strong>
    </p>
    <p>
      <strong>
        NO PURCHASE OR PAYMENT NECESSARY. A PURCHASE WILL NOT IMPROVE YOUR
        CHANCE OF WINNING. VOID WHERE PROHIBITED.
      </strong>
    </p>
    <p>
      <strong>
        BY ENTERING THE SWEEPSTAKES, ENTRANT AGREES TO THESE OFFICIAL RULES.
      </strong>
    </p>
    <p>
      <strong>SPONSOR/PROMOTIONAL PARTNER.</strong> The Promotion is sponsored
      by Rockstar, Inc. (“Sponsor”) and AMPM. (“Promotional Partner”).
    </p>
    <ol>
      <li>
        <p>
          <strong>Eligibility:</strong> The Rockstar & AMPM Destiny 2
          Sweepstakes (the “Sweepstakes”) is open to legal residents of Arizona,
          California, Nevada, Oregon and Washington (“Eligibility Area”) who are
          18 years of age or older as of the Start Date of the Promotion.
          Directors, officers, employees, agents and representatives of Sponsor,
          Sponsor’s franchisees, and Promotional Partner, and each of their
          respective parent, sister, affiliates, subsidiaries, advertising and
          promotional agencies, and all other corporations, partnerships, sole
          proprietorships or other legal entities or individuals associated with
          the development, administration, or fulfillment of this Promotion
          (collectively, the “Promotion Entities”), and the immediate families
          of such persons (defined as spouse, child, sibling, parent or
          grandparent or so-called “step” or “half” variations of any of the
          preceding individuals, regardless of where they live), and those
          living in their same households, whether or not related, or a winner
          of any other of Sponsor’s official Sweepstakes during 180 days prior
          to the start date of the above-referenced Sweepstakes are ineligible
          to enter, win or collect prizes in the Promotion. The Promotion is
          subject to all applicable federal, state, provincial, territorial,
          municipal, and local laws, regulations, and ordinances.
        </p>
        <p>
          Participation constitutes entrant’s full and unconditional agreement
          to these Official Rules and Sponsor’s decisions, which are final and
          binding in all matters related to the Sweepstakes.  Winning a prize is
          contingent upon fulfilling all requirements set forth herein.
        </p>
      </li>
      <li>
        <p>
          <strong>Sweepstakes Entry Period:</strong> The Sweepstakes begins on
          August 7, 2018 at 12:00:01 PM Pacific Time (“PT”) and ends on October
          2, 2018 at 11:59:59 AM PT (the “Sweepstakes Period”). The Website
          (defined below) clock is the official timekeeper for this Sweepstakes.
        </p>
      </li>
      <li>
        <p>
          <strong>How to Enter:</strong> During the Sweepstakes Period, look for
          a code under the tab of specially-marked cans of Rockstar #TMGS
          (“Code”). Once you have your Code, to register to enter the
          Sweepstakes, visit{' '}
          <a href="https://destiny2.rockstarenergy.com/ampm">
            destiny2.rockstarenergy.com/ampm
          </a>{' '}
          (“Website”) find and complete the registration form by providing your
          name, email address, mobile number, and creating a password to log in.
          Once you’ve registered, input your Code where indicated on the Website
          to automatically receive one (1) entry into the Grand Prize Entry
          Pool. In the event that ten (10) invalid Codes are entered within
          fifteen (15) minutes, the user’s account will be frozen for one (1)
          hour. In the event of a dispute as to the identity of entrant, entry
          will be deemed made by the authorized account holder of the e-mail
          address used to enter the Sweepstakes, as determined by Sponsor, in
          its sole discretion. Entry information must be manually key-stroked;
          use of automated means of entry is prohibited and entries made via
          such improper means will be disqualified. Responsibility for receipt
          of entry rests solely with the entrant.
        </p>
        <p>
          To enter without purchase and without obtaining a code (“AMOE”), send
          a 3x5 paper/card with your complete name, address, email address,
          telephone number, birth date, and the Daily Entry Date you are
          entering, and send to the following mailing address: P O Box 950072
          Atlanta, GA  30377, ATTN: Brandmovers, Inc., to be received by October
          9, 2018 to be entered into the Grand Prize Entry Pool. Limit one (1)
          AMOE request per outer envelope. All entries become the property of
          Sponsor and will not be acknowledged or returned. Proof of submission
          of completed entry form does not constitute proof of receipt or proof
          of entry in Sweepstakes.
        </p>
      </li>
      <li>
        <p>
          <strong>RANDOM DRAWINGS:</strong> Ten (10) Grand Prize Winners will be
          selected via a random drawing that will occur on or about October 15,
          2018. The potential Winners will be notified by email on or about the
          day of the random drawing, and each will have seventy-two (72) hours
          from the time a Winner notification is sent by Sponsor to respond to
          the notification. In the event a potential Winner fails to respond to
          a Winner notification with seventy-two (72) hours, he/she will forfeit
          his/her right to the Prize and the Prize may, at Sponsor’s sole
          discretion, be awarded to an alternate Winner, time permitting.
        </p>
        <p>
          Upon receiving the Winner notification response, Sponsor may send each
          potential Winner an Affidavit of Eligibility and Publicity (where
          legal) and Liability Release, which will need to be completed, signed
          and returned to Sponsor within seven (7) days of receipt of the
          Release. If any Prize notification or attempted notification is
          returned as undeliverable, that Prize may be forfeited and awarded to
          an alternate Winner, time permitting. Sponsor is not responsible for
          lost, late, damaged, delayed, or illegible paperwork. By signing the
          Release, Winners acknowledge compliance with these Official Rules.
          Odds of winning a Prize depend upon the number of eligible entries
          received.
        </p>
      </li>
      <li>
        <p>
          <strong>CONDITIONS FOR AWARDING PRIZE:</strong> Winners must meet all
          eligibility requirements. In the event a potential Winner is deemed
          ineligible or is not available to claim a Prize during the specified
          dates, then an alternate Winner may be selected at random from
          eligible entries received, time permitting.
        </p>
      </li>
      <li>
        <p>
          <strong>
            Prizes and Approximate Retail Values (“ARVS”): Ten (10) Grand Prize
            Winners
          </strong>{' '}
          - A PS4™ Pro System, Destiny 2: Forsaken Game for the PS4™ system,
          headphones and Destiny 2 Collectibles to be determined by the Sponsor.
          ARV: $1,000.00 each. Total ARV of all prizes is: $10,000.00 USD. Any
          and all guarantees and warranties for any Prizes are subject to the
          manufacturer’s terms and conditions, and the Winner agrees to look
          solely to such manufacturers for any such warranty or guarantee claim.
          Prize will be awarded as is. Prizes consist of only those items
          specifically listed as part of the prize. No cash or other
          substitution allowed by any Winner, but Sponsor may substitute a
          Prize, or any portion thereof, for a prize of equal or greater value
          at Sponsor’s sole discretion for any reason. The Prizes are
          non-assignable and non-transferable. Winners are responsible for
          paying all applicable income taxes associated with accepting their
          Prize. A 1099 form may be submitted to all appropriate taxing
          authorities, if applicable.
        </p>
      </li>
      <li>
        <p>
          <strong>PRIVACY:</strong> Promotion Entities and their authorized
          agents will collect, use, and disclose the personal information you
          provide when you enter the Promotion solely for the purposes of
          administering the Promotion and fulfilling prizes, and by entering
          this Promotion you consent to the collection, use, disclosure and
          management of your personal information for said purposes. All
          personal information that Entrants submit will be subject to Sponsor’s
          privacy policy. In the event of any discrepancy between Sponsor’s
          privacy policy and these Official Rules, the privacy policy will
          govern and control. For a copy of Sponsor’s complete online privacy
          policy please visit{' '}
          <a
            href="http://rockstarenergy.com/company/privacy"
            target="_blank"
            rel="noreferrer noopener"
          >
            http://rockstarenergy.com/company/privacy
          </a>
          . Information collected by Sponsor in connection with this promotion
          will be maintained on servers located in the United States.
        </p>
      </li>
      <li>
        <p>
          <strong>Entry Conditions and Release:</strong> By entering, each
          entrant agrees to: (a) comply with and be bound by these Official
          Rules and the decisions of the Sponsor which are binding and final in
          all matters relating to this Sweepstakes; (b) release and hold
          harmless the Sweepstakes Entities from and against any and all claims,
          expenses, and liability, including but not limited to negligence and
          damages of any kind to persons and property, including but not limited
          to invasion of privacy (under appropriation, intrusion, public
          disclosure of private facts, false light in the public eye or other
          legal theory), defamation, slander, libel, violation of right of
          publicity, infringement of trademark, copyright or other intellectual
          property rights, property damage, or death or personal injury arising
          out of or relating to a participant’s entry, creation of an entry or
          submission of an entry, participation in the Sweepstakes, the
          Submission, acceptance or use or misuse of prize  and/or the
          broadcast, exploitation or use of entry or the Submission; and (c)
          indemnify, defend and hold harmless the Sweepstakes Entities from and
          against any and all claims, expenses, and liabilities (including
          reasonable attorney’s fees) arising out of or relating to an entrant’s
          participation in the Sweepstakes and/or entrant’s acceptance, use or
          misuse of prize or any portion thereof.
        </p>
      </li>
      <li>
        <p>
          <strong>General Conditions:</strong> Sponsor reserves the right to
          cancel, suspend and/or modify the Sweepstakes, or any part of it, for
          any reason whatsoever, including, without limitation, fire, flood,
          natural or man-made epidemic of health of other means, earthquake,
          explosion, labor dispute or strike, act of God or public enemy,
          satellite or equipment failure, riot or civil disturbance, terrorist
          threat or activity, war (declared or undeclared) or any federal state
          or local government law, order, or regulation, public health crisis,
          order of any court or jurisdiction or if any fraud, technical failures
          or any other factor beyond Sponsor’s reasonable control impairs the
          integrity or proper functioning of the Sweepstakes, as determined by
          Sponsor in its sole discretion. If the Sweepstakes is terminated
          before the designated end date, Sponsor will (if possible) select the
          winner by random drawing from all eligible, non-suspect entries
          received as of the date of the event giving rise to the termination.
          Inclusion in such random drawing shall be each entrant’s sole and
          exclusive remedy under such circumstances. Sponsor reserves the right
          in its sole discretion to disqualify any individual it finds to be
          tampering with the entry process or the operation of the Sweepstakes
          or to be acting in violation of these Official Rules or any other
          promotion or in an unsportsmanlike or disruptive manner. Any attempt
          by any person to deliberately undermine the legitimate operation of
          the Sweepstakes may be a violation of criminal and civil law, and,
          should such an attempt be made, Sponsor reserves the right to seek
          damages from any such person to the fullest extent permitted by law.
          Only the type and quantity of prizes described in these Official Rules
          will be awarded. The invalidity or unenforceability of any provision
          of these rules shall not affect the validity or enforceability of any
          other provision. In the event that any provision is determined to be
          invalid or otherwise unenforceable or illegal, these rules shall
          otherwise remain in effect and shall be construed in accordance with
          their terms as if the invalid or illegal provision were not contained
          herein. Sponsor’s failure to enforce any term of these Official Rules
          shall not constitute a waiver of that provision.
        </p>
      </li>
      <li>
        <p>
          <strong>Limitations of Liability:</strong> The Sweepstakes Entities as
          set forth above are not responsible for: (1) any incorrect or
          inaccurate information, whether caused by entrants, printing errors or
          by any of the equipment or programming associated with or utilized in
          the Sweepstakes; (2) technical failures of any kind, including, but
          not limited to malfunctions, interruptions, or disconnections in phone
          lines or network hardware or software; (3) unauthorized human
          intervention in any part of the entry process or the Sweepstakes; (4)
          technical or human error which may occur in the administration of the
          Sweepstakes or the processing of entries; or (5) any injury or damage
          to persons or property which may be caused, directly or indirectly, in
          whole or in part, from entrant’s participation in the Sweepstakes or
          receipt or use or misuse of any Prize. If for any reason an entry is
          confirmed to have been erroneously deleted, lost, or otherwise
          destroyed or corrupted, entrant’s sole remedy is another entry in the
          Sweepstakes, provided that if it is not possible to award another
          entry due to discontinuance of the Sweepstakes, or any part of it, for
          any reason, Sponsor, at its discretion, may elect to hold judging from
          among all eligible entries received up to the date of discontinuance
          for any or all of the Prizes offered herein. Entrant (or entrant’s
          parent/legal guardian, if entrant is a minor in the state of his/her
          residence) further agrees and acknowledges that Sponsor reserves the
          right to forfeit or award any unclaimed or leftover Prize at its sole
          discretion.
        </p>
      </li>
      <li>
        <p>
          <strong>Disputes/Governing Law:</strong> Entrant agrees that: (i) any
          and all disputes, claims and causes of action arising out of or
          connected with this Sweepstakes, other than those concerning the
          administration of the Sweepstakes or the determination of winner or
          for any disputes arising from the loss or injury from the
          participation in a Prize, shall be resolved individually, without
          resort to any form of class action; (ii) any disputes arising out of
          these Official Rules (except for any disputes arising from the loss or
          injury from the use of Prizes) shall be submitted to final, binding
          arbitration conducted in Nevada, under the Arbitration Rules and
          Procedures of the Judicial Arbitration and Mediation Services Inc.
          before a single, neutral arbitrator who is a former or retired Nevada
          state or federal court judge with experience in entertainment matters
          who shall follow Nevada law and the Federal Rules of Evidence and have
          no authority to award punitive damages. Either party may enforce a
          final arbitration award in any court of competent jurisdiction in
          Nevada, including an award of costs, fees and expenses incurred in
          enforcing the award. Notwithstanding the foregoing, Sweepstakes
          Entities shall be entitled to seek injunctive relief (unless otherwise
          precluded by any other provision of these Official Rules) in the state
          and federal courts of Nevada. Any dispute or portion thereof, or any
          claim for a particular form of relief (not otherwise precluded by any
          other provision of these Official Rules), that may not be arbitrated
          pursuant to applicable state or federal law may be heard only in a
          court of competent jurisdiction in Nevada; (iii) any and all claims,
          judgments and awards shall be limited to actual out-of- pocket costs
          incurred, including costs associated with entering this Sweepstakes,
          but in no event attorneys’ fees; and (iv) under no circumstances will
          entrant be permitted to obtain awards for, and entrant hereby waives
          all rights to claim punitive, incidental and consequential damages and
          any other damages, other than for actual out- of-pocket expenses, and
          any and all rights to have damages multiplied or otherwise increased.
          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF
          LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY
          NOT APPLY TO YOU. All issues and questions concerning the
          construction, validity, interpretation and enforceability of these
          Official Rules, or the rights and obligations of the entrant and
          Sponsor in connection with the Sweepstakes, shall be governed by, and
          construed in accordance with, the laws of the State of Nevada, without
          giving effect to any choice of law or conflict of law rules (whether
          of the State of Nevada, or any other jurisdiction), which would cause
          the application of the laws of any jurisdiction other than the State
          of Nevada.
        </p>
      </li>
      <li>
        <p>
          <strong>WINNER NAMES AND OFFICIAL RULES:</strong> For the names of the
          Winner and/or a copy of these Official Rules, send an email request to{' '}
          <a href="mailto:sweepstakes@rockstarenergy.com">
            SWEEPSTAKES@ROCKSTARENERGY.COM
          </a>{' '}
          with the subject line “Rockstar & AMPM Destiny 2 Sweepstakes”. All
          requests for a copy of the Official Rules and the names of winners
          must be made by November 2, 2018. Please note in the subject line of
          your correspondence whether you are requesting “Official Rules” or the
          names of “Prize Winners.”
        </p>
      </li>
      <li>
        <p>
          <strong>Sponsor:</strong> Rockstar, Inc., 101 Convention Center Dr.,
          Suite 777, Las Vegas, NV 89109.
        </p>
      </li>
    </ol>
    <p>
      Sony Interactive Entertainment LLC is not a sponsor of this Sweepstakes.
    </p>
    <p>
      Neither Activision Publishing, Inc. nor Bungie, Inc. are sponsors of the
      sweepstakes. © 2018 Bungie, Inc. All rights reserved. Destiny, the Destiny
      logo, Bungie and the Bungie logo are among the trademarks of Bungie, Inc.
      Published and distributed by Activision. “PlayStation”, and the “PS”
      Family logo are registered trademarks, and “PS4” is a trademark of Sony
      Interactive Entertainment Inc. ESRB Rating: T for Teen.
    </p>
    <p>
      <strong>POS Abbreviated Rules</strong>
    </p>
    <p>
      <strong>
        ROCKSTAR & AMPM DESTINY 2 SWEEPSTAKES. NO PURCHASE NECESSARY TO ENTER OR
        WIN.
      </strong>{' '}
      Open to residents of AZ, CA, NV, OR and WA who are at least 18 years of
      age or older. Void outside of AZ, CA, NV, OR, WA and where prohibited.
      Starts at 12:00:01 PM PT on 8/7/18 and ends at 11:59:59 AM PT on 10/2/18.
      For official rules, visit
      <a href="https://destiny2.rockstarenergy.com/ampm">
        destiny2.rockstarenergy.com/ampm
      </a>
      . Sponsor: Rockstar Inc. Neither Activision Publishing, Inc. nor Bungie,
      Inc. are sponsors of the sweepstakes. © 2018 Bungie, Inc. All rights
      reserved. Destiny, the Destiny logo, Bungie and the Bungie logo are among
      the trademarks of Bungie, Inc. Published and distributed by Activision.
      “PlayStation”, and the “PS” Family logo are registered trademarks, and
      “PS4” is a trademark of Sony Interactive Entertainment Inc. ESRB Rating: T
      for Teen. Sony Interactive Entertainment LLC is not a sponsor of this
      promotion.
    </p>
  </div>
);

export default RulesAmPm;

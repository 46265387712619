// Fonts
export const fontFamily =
  'Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif';

// Line Heights
export const lineHeight = 1.71;

// Sizes
export const maxWidth = 1270;

// space
export const space = 8;

// Colors
export const white = '#fff';
export const black = '#000';
export const brightYellow = '#fff200';

export const brand1 = brightYellow;

export const primary = brightYellow;
export const secondary = white;
export const info = 'rgba(0, 0, 0, 0.3)';

export const textColor = black;
export const bgColor = black;
export const borderColor = white;
export const overlayColor = 'rgba(86, 96, 117, 0.7)';

// Radius
export const radius = 4;

// Transitions
export const transitionFast = '200ms';
export const transitionMid = '350ms';
export const transitionSlow = '500ms';

// Breakpoints
export const xsMax = 'max-width: 767px';
export const smMin = 'min-width: 768px';
export const smMax = 'max-width: 1023px';
export const mdMin = 'min-width: 1024px';
export const mdMax = 'max-width: 1439px';
export const lgMin = 'min-width: 1440px';

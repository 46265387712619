import React, { Fragment } from 'react';

import FooterStyled from './Footer.style';
import logoEsrb from '../../images/logo_esrb_rating.png';
import logoRs from '../../images/logo_rs_text.svg';
import logoDestiny2 from '../../images/logo_destiny2.svg';
import logoActivision from '../../images/logo_activision.svg';
import logoBungie from '../../images/logo_bungie.png';
import logoSlingshot from '../../images/logo_slingshot.svg';
import logoPlaystation from '../../images/logo_playstation.png';
import logoOblivion from '../../images/logo_oblivion.svg';

import ModalController from '../ModalController/ModalController';
import ModalRules from '../ModalRules/ModalRules';
import ModalPrivacy from '../ModalPrivacy/ModalPrivacy';
import CookieDisclaimer from '../CookieDisclaimer/CookieDisclaimer';

const footerLogos = [
  { href: null, image: logoEsrb },
  { href: 'http://rockstarenergy.com/', image: logoRs },
  { href: 'https://www.destinythegame.com/', image: logoDestiny2 },
  { href: 'https://www.activision.com/', image: logoActivision },
  { href: 'https://www.bungie.net/', image: logoBungie },
  { href: 'https://slingshot.polaris.com/', image: logoSlingshot },
  {
    href: 'https://www.playstation.com/en-us/games/destiny-2-ps4/',
    image: logoPlaystation,
  },
];

const Footer = () => {
  const logos = footerLogos.map(logo => (
    <li className="Footer__logo" key={logo.href}>
      <a
        className="Footer__logoLink"
        href={logo.href}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={logo.image} alt="" />
      </a>
    </li>
  ));

  return (
    <FooterStyled>
      <div className="Footer__inner">
        <div className="Footer__rules">
          <a
            className="Footer__rulesBtn"
            href="https://support.rockstarenergy.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            SUPPORT
          </a>
          <span />

          <ModalController>
            {(isOpen, handleToggle) => (
              <Fragment>
                <button
                  className="Footer__rulesBtn"
                  type="button"
                  onClick={handleToggle}
                >
                  OFFICIAL RULES
                </button>

                {isOpen && <ModalRules onClose={handleToggle} />}
              </Fragment>
            )}
          </ModalController>
          <span />
          <ModalController>
            {(isOpen, handleToggle) => (
              <Fragment>
                <button
                  className="Footer__rulesBtn"
                  type="button"
                  onClick={handleToggle}
                >
                  PRIVACY POLICY
                </button>

                {isOpen && <ModalPrivacy onClose={handleToggle} />}
              </Fragment>
            )}
          </ModalController>
        </div>

        <ul className="Footer__logos">{logos}</ul>

        <div className="Footer__powered">
          <a
            className="Footer__poweredLink"
            href="https://oblivion.live"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>powered by</span>
            <img src={logoOblivion} alt="Oblivion" />
          </a>
        </div>
      </div>

      <CookieDisclaimer />
    </FooterStyled>
  );
};

export default Footer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ModalRulesStyled from '../ModalRules/ModalRules.style';

import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import ModalClose from '../ModalClose/ModalClose';

import PrivacyUsCa from './PrivacyUsCa';
import PrivacySlingshot from './PrivacySlingshot';

class ModalPrivacy extends Component {
  constructor(props) {
    super(props);

    this.state = { activePrivacy: null, isActive: null };
  }

  componentDidMount() {
    this.onSwitchRule('intl');
  }

  onSwitchRule = rule => {
    const activePrivacy = this.getActiveRule(rule);

    this.setState({ activePrivacy, isActive: rule });
  };

  getActiveRule = rule => {
    return {
      intl: <PrivacyUsCa />,
      slingshot: <PrivacySlingshot />,
    }[rule];
  };

  render() {
    const { onClose } = this.props;
    const { activePrivacy, isActive } = this.state;

    return (
      <Modal>
        <ModalClose onClick={onClose} />
        <ModalRulesStyled>
          <Heading size="xs" full>
            Website Privacy Policy
          </Heading>
          <div className="ModalRules__desc">
            <span>Quick Links</span>
            <br />
            <div className="ModalRules__links">
              <Button
                className={cx('ModalRules__link', {
                  active: isActive === 'intl',
                })}
                size="sm"
                variant="secondary"
                onClick={() => this.onSwitchRule('intl')}
              >
                Website Privacy
              </Button>

              <Button
                className={cx('ModalRules__link', {
                  active: isActive === 'slingshot',
                })}
                size="sm"
                variant="secondary"
                onClick={() => this.onSwitchRule('slingshot')}
              >
                Slingshot Privacy
              </Button>
            </div>
            <hr />
            {activePrivacy}
          </div>
          <Button
            className="ModalRules__back"
            variant="secondary"
            onClick={onClose}
          >
            BACK
          </Button>
        </ModalRulesStyled>
      </Modal>
    );
  }
}

ModalPrivacy.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalPrivacy;

import styled from 'styled-components';
import * as defaultTheme from '../variables';

import bgSocialMobile from '../../images/bg_Social_mobile.jpg';
import bgSocialTab from '../../images/bg_Social_tab.jpg';
import bgSocialDesktop from '../../images/bg_Social_dsktp.jpg';
import logoD2 from '../../images/logo_d2_icon.svg';
import logoRs from '../../images/logo_rs_star.svg';

const SocialStyled = styled('div')`
  padding: 75px 0;
  color: ${p => p.theme.white};
  background-image: url(${bgSocialMobile});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  @media (${p => p.theme.smMin}) {
    background-image: url(${bgSocialTab});
  }

  @media (${p => p.theme.lgMin}) {
    background-image: url(${bgSocialDesktop});
  }

  .Social__title {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .Social__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      max-width: 708px;
      margin: 0 auto;
      padding: 0;
    }

    @media (min-width: 1440px) {
      max-width: 729px;
    }
  }

  .Social__block {
    min-width: 320px;

    &:not(:last-child) {
      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }
  }

  .Social__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    max-width: 335px;
    height: 57px;
    margin-bottom: 15px;
    padding-left: 90px;
    padding-right: 20px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.75);
    font-size: 12px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 1.2px;
    text-align: left;
    text-transform: uppercase;

    &.d2 {
      background-image: url(${logoD2});
      background-repeat: no-repeat;
      background-size: 53px 30px;
      background-position: 20px 50%;
    }

    &.rs {
      background-image: url(${logoRs});
      background-repeat: no-repeat;
      background-size: 43px 42px;
      background-position: 20px 50%;
    }

    span {
      display: block;
      max-width: 100%;
      flex-shrink: 1;
    }
  }

  .Social__buttons {
    display: flex;
    justify-content: space-between;
  }

  .Social__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 71px;
    height: 71px;
    font-size: 36px;
    transition: opacity ${p => p.theme.transitionMid};

    &:hover {
      opacity: 0.7;
    }

    &.yt {
      background-color: #be2018;
    }

    &.tw {
      background-color: #57a4da;
    }

    &.fb {
      background-color: #385899;
    }

    &.ig {
      background: #d14667;
      background: linear-gradient(to bottom, #d14667 0%, #f37c32 100%);
    }
  }
`;

SocialStyled.defaultProps = {
  theme: defaultTheme,
};

export default SocialStyled;
